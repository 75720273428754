import ContentTranslator from "../translator/ContentTranslator";
import TableBodyActionPlan from "./TableBodyActionPlan";
import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";

const tableBodyStyle = {
    fontSize: 11,
    fontFamily: "IBMPlexSans",
};

class ActionPlanSkillProfiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            skillLevelDescriptionShow: false,
            poppedUpLevel: null,
        };

        this.openSkillLevelDescriptionModal = this.openSkillLevelDescriptionModal.bind(this);
        this.togleSkillLevelDescriptionModal = this.togleSkillLevelDescriptionModal.bind(this);
    }

    componentDidMount() {}

    renderSkillCategories(categories) {
        const categoriesRows = categories.map((category, index) => {
            return <React.Fragment key={index}>{this.renderSkillSubCategories(category)}</React.Fragment>;
        });

        return categoriesRows;
    }

    renderSkillSubCategories(category) {
        const content = category.subCategories.map((subCategory, subIndex) => {
            const shownLevel = subCategory.skills.filter((skill) => skill.levels.some((level) => level.show));
            let subCatSkillProfilesCodeShown = subCategory.skills.filter((skill) => skill.levels.some((level) => level.show)).map((skill) => skill.skillsCode);
            const checkedSubCatSkills = this.props.showAll ? subCategory.skills : subCategory.skills.filter((it) => subCatSkillProfilesCodeShown.includes(it.skillsCode));
            const subCategoryHidden = !checkedSubCatSkills.some((skill) => !skill.isHidden) && !this.props.showHiddenSkills;

            return (
                (shownLevel.length > 0 || this.props.showAll) && (
                    <React.Fragment key={subIndex}>
                        <thead className="tableHeader" style={{ display: !subCategoryHidden ? "" : "none" }}>
                            <tr>
                                <th className={"th-start"} style={{ backgroundColor: `${category.colour}` }}>
                                    <ContentTranslator page="Categories" name={category.name} contentText={category.name} />
                                </th>
                                <th style={{ width: "140px", backgroundColor: `${category.colour}` }}>
                                    <ContentTranslator page="SubCategories" name={subCategory.name} contentText={subCategory.name} />
                                </th>
                                <th colSpan="2" style={{ backgroundColor: `${category.colour}` }}></th>
                                <th className={"th-fixwidth"} style={{ backgroundColor: `${category.colour}` }}></th>
                                <th className={"th-fixwidth"} style={{ backgroundColor: `${category.colour}` }}></th>
                                <th className={"th-fixwidth th-end"} style={{ backgroundColor: `${category.colour}` }}></th>
                            </tr>
                            <tr style={{ color: "#2B2B2B" }}>
                                <th className="border-0"></th>
                                <th className="border-0">
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanSkill" contentText="Skill" />
                                </th>
                                <th colSpan="2">
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanSkillLevel" contentText="Skill Level" />
                                </th>
                                <th className="text-center">
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanGap" contentText="Gap" />
                                </th>
                                <th className="text-center">
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanRequirement" contentText="Requirement" />
                                </th>
                                <th className="text-center">
                                    <ContentTranslator page="SfiaPlan" name="SfiaPlanAddAction" contentText="Add Action" />
                                </th>
                            </tr>
                        </thead>
                        {this.renderSkills(subCategory)}
                        <tbody className="border-0" style={{ display: !subCategoryHidden ? "" : "none" }}>
                            <tr>
                                <td className="border-0"></td>
                            </tr>
                        </tbody>
                    </React.Fragment>
                )
            );
        });

        return content;
    }

    renderSkills(subCategory) {
        return (
            <tbody style={tableBodyStyle}>
                {subCategory.skills.map((skill, skillIndex) => {
                    const skillHidden = skill.isHidden && !this.props.showHiddenSkills;

                    return (
                        <React.Fragment key={skillIndex}>
                            {skill.levels.map((level, levelIndex) => {
                                return (
                                    (level.show || this.props.showAll) && (
                                        <TableBodyActionPlan
                                            key={levelIndex}
                                            showAll={this.props.showAll}
                                            showHiddenSkills={this.props.showHiddenSkills}
                                            skillHidden={skillHidden}
                                            levelIndex={levelIndex}
                                            skill={{
                                                id: skill.id,
                                                skillsCode: skill.skillsCode,
                                                name: skill.name,
                                            }}
                                            level={level}
                                            openSkillLevelDescriptionModal={this.openSkillLevelDescriptionModal}
                                            optionsActionTypes={this.props.optionsActionTypes}
                                            optionsActionSkills={this.props.optionsActionSkills}
                                            jobLorProfiles={this.props.jobLorProfiles}
                                            jobSkillProfiles={this.props.jobSkillProfiles}
                                            categories={this.props.categories}
                                            lorProfiles={this.props.lorProfiles}
                                            skillProfiles={this.props.skillProfiles}
                                            handleActionPlanAdded={this.props.handleActionPlanAdded}
                                            providers={this.props.providers}
                                            allowUpdate={this.props.allowUpdate}
                                            optionsJob={this.props.optionsJob}
                                            generateAlert={this.props.generateAlert}
                                        ></TableBodyActionPlan>
                                    )
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </tbody>
        );
    }

    openSkillLevelDescriptionModal(level) {
        this.setState({ poppedUpLevel: level, skillLevelDescriptionShow: true });
    }

    togleSkillLevelDescriptionModal() {
        const skillLevelDescriptionShow = this.state.skillLevelDescriptionShow;
        this.setState({ skillLevelDescriptionShow: !skillLevelDescriptionShow });
    }

    render() {
        const { poppedUpLevel } = this.state;
        return (
            <React.Fragment>
                <Modal isOpen={this.state.skillLevelDescriptionShow} toggle={this.togleSkillLevelDescriptionModal}>
                    <ModalHeader toggle={this.togleSkillLevelDescriptionModal}>{poppedUpLevel && `${poppedUpLevel.skillsCode}-${poppedUpLevel.level}`}</ModalHeader>
                    <ModalBody>{poppedUpLevel && <div dangerouslySetInnerHTML={{ __html: poppedUpLevel.description }}></div>}</ModalBody>
                </Modal>
                {this.state.loading ? (
                    <React.Fragment>
                        <tbody className="border-0">
                            <tr>
                                <td className="border-0" colSpan="9"></td>
                            </tr>
                            <tr>
                                <td className="border-0" colSpan="9">
                                    <div style={{ textAlign: "center" }}>
                                        <Spinner animation="border" role="status" style={{ marginLeft: "20px" }}></Spinner>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </React.Fragment>
                ) : (
                    this.renderSkillCategories(this.props.categories)
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionPlanSkillProfiles);
