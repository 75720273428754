export const GET_COUNTRIES_START = "GET_COUNTRIES_START";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

export const GET_REGISTER_REASONS_START = "GET_REGISTER_REASONS_START";
export const GET_REGISTER_REASONS_SUCCESS = "GET_REGISTER_REASONS_SUCCESS";
export const GET_REGISTER_REASONS_FAIL = "GET_REGISTER_REASONS_FAIL";

export const GET_LORS_START = "GET_LORS_START";
export const GET_LORS_SUCCESS = "GET_LORS_SUCCESS";
export const GET_LORS_FAIL = "GET_LORS_FAIL";

export const UPDATE_LOR_START = "UPDATE_LOR_START";
export const UPDATE_LOR_SUCCESS = "UPDATE_LOR_SUCCESS";
export const UPDATE_LOR_FAIL = "UPDATE_LOR_FAIL";

export const UPDATE_LOR_SET_ISHIDDEN_START = "UPDATE_LOR_SET_ISHIDDEN_START";
export const UPDATE_LOR_SET_ISHIDDEN_SUCCESS = "UPDATE_LOR_SET_ISHIDDEN_SUCCESS";
export const UPDATE_LOR_SET_OPTIONAL_SUCCESS = "UPDATE_LOR_SET_OPTIONAL_SUCCESS";
export const UPDATE_LOR_SET_ISHIDDEN_FAIL = "UPDATE_LOR_SET_ISHIDDEN_FAIL";

export const GET_CATEGORIES_START = "GET_CATEGORIES_START";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

export const REGISTER_USER_START = "REGISTER_USER_START";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const GET_PERSON_MYPROFILE_START = "GET_PERSON_MYPROFILE_START";
export const GET_PERSON_MYPROFILE_SUCCESS = "GET_PERSON_MYPROFILE_SUCCESS";
export const GET_PERSON_MYPROFILE_FAIL = "GET_PERSON_MYPROFILE_FAIL";

export const DOWNLOAD_PERSON_MYPROFILE_START = "DOWNLOAD_PERSON_MYPROFILE_START";
export const DOWNLOAD_PERSON_MYPROFILE_SUCCESS = "DOWNLOAD_PERSON_MYPROFILE_SUCCESS";
export const DOWNLOAD_PERSON_MYPROFILE_FAIL = "DOWNLOAD_PERSON_MYPROFILE_FAIL";

export const GET_PERSON_LATESTACCOUNT_START = "GET_PERSON_LATESTACCOUNT_START";
export const GET_PERSON_LATESTACCOUNT_SUCCESS = "GET_PERSON_LATESTACCOUNT_SUCCESS";
export const GET_PERSON_LATESTACCOUNT_FAIL = "GET_PERSON_LATESTACCOUNT_FAIL";

export const GET_MANAGED_PEOPLE_LATESTACCOUNT_START = "GET_MANAGED_PEOPLE_LATESTACCOUNT_START";
export const GET_MANAGED_PEOPLE_LATESTACCOUNT_SUCCESS = "GET_MANAGED_PEOPLE_LATESTACCOUNT_SUCCESS";
export const GET_MANAGED_PEOPLE_LATESTACCOUNT_FAIL = "GET_MANAGED_PEOPLE_LATESTACCOUNT_FAIL";

export const CLEAR_PERSON_ACCOUNT_STATE = "CLEAR_PERSON_ACCOUNT_STATE";

export const UPDATE_PERSON_MYPROFILE_START = "UPDATE_PERSON_MYPROFILE_START";
export const UPDATE_PERSON_MYPROFILE_SUCCESS = "UPDATE_PERSON_MYPROFILE_SUCCESS";
export const UPDATE_PERSON_MYPROFILE_FAIL = "UPDATE_PERSON_MYPROFILE_FAIL";

export const DOWNLOAD_PERSON_PROFILE_PICTURE_START = "DOWNLOAD_PERSON_PROFILE_PICTURE_START";
export const DOWNLOAD_PERSON_PROFILE_PICTURE_SUCCESS = "DOWNLOAD_PERSON_PROFILE_PICTURE_SUCCESS";
export const DOWNLOAD_PERSON_PROFILE_PICTURE_FAIL = "DOWNLOAD_PERSON_PROFILE_PICTURE_FAIL";

export const UPLOAD_PERSON_PROFILE_PICTURE_START = "UPLOAD_PERSON_PROFILE_PICTURE_START";
export const UPLOAD_PERSON_PROFILE_PICTURE_SUCCESS = "UPLOAD_PERSON_PROFILE_PICTURE_SUCCESS";
export const UPLOAD_PERSON_PROFILE_PICTURE_FAIL = "UPLOAD_PERSON_PROFILE_PICTURE_FAIL";

export const UPDATE_PERSON_ACCOUNT_START = "UPDATE_PERSON_ACCOUNT_START";
export const UPDATE_PERSON_ACCOUNT_SUCCESS = "UPDATE_PERSON_ACCOUNT_SUCCESS";
export const UPDATE_PERSON_ACCOUNT_FAIL = "UPDATE_PERSON_ACCOUNT_FAIL";

export const UPDATE_PERSON_ACCOUNT_NOTE_START = "UPDATE_PERSON_ACCOUNT_NOTE_START";
export const UPDATE_PERSON_ACCOUNT_NOTE_SUCCESS = "UPDATE_PERSON_ACCOUNT_NOTE_SUCCESS";
export const UPDATE_PERSON_ACCOUNT_NOTE_FAIL = "UPDATE_PERSON_ACCOUNT_NOTE_FAIL";

export const UPDATE_MANAGED_PEOPLE_PERSON_ACCOUNT_NOTE_START = "UPDATE_MANAGED_PEOPLE_PERSON_ACCOUNT_NOTE_START";
export const UPDATE_MANAGED_PEOPLE_PERSON_ACCOUNT_NOTE_SUCCESS = "UPDATE_MANAGED_PEOPLE_PERSON_ACCOUNT_NOTE_SUCCESS";
export const UPDATE_MANAGED_PEOPLE_PERSON_ACCOUNT_NOTE_FAIL = "UPDATE_MANAGED_PEOPLE_PERSON_ACCOUNT_NOTE_FAIL";

export const PERSON_ACCOUNT_REQUEST_APPROVAL_START = "PERSON_ACCOUNT_REQUEST_APPROVAL_START";
export const PERSON_ACCOUNT_REQUEST_APPROVAL_SUCCESS = "PERSON_ACCOUNT_REQUEST_APPROVAL_SUCCESS";
export const PERSON_ACCOUNT_REQUEST_APPROVAL_FAIL = "PERSON_ACCOUNT_REQUEST_APPROVAL_FAIL";

export const UPDATE_PERSON_ACTIVITYSTATEMENTINTERVAL_START = "UPDATE_PERSON_ACTIVITYSTATEMENTINTERVAL_START";
export const UPDATE_PERSON_ACTIVITYSTATEMENTINTERVAL_SUCCESS = "UPDATE_PERSON_ACTIVITYSTATEMENTINTERVAL_SUCCESS";
export const UPDATE_PERSON_ACTIVITYSTATEMENTINTERVAL_FAIL = "UPDATE_PERSON_ACTIVITYSTATEMENTINTERVAL_FAIL";

export const AUTH_REGISTER_START = "AUTH_REGISTER_START";
export const AUTH_REGISTER_SUCCESS = "AUTH_REGISTER_SUCCESS";
export const AUTH_REGISTER_FAIL = "AUTH_REGISTER_FAIL";

export const AUTH_REGISTER_EMAIL_CHECKING_START = "AUTH_REGISTER_EMAIL_CHECKING_START";
export const AUTH_REGISTER_EMAIL_CHECKING_SUCCESS = "AUTH_REGISTER_EMAIL_CHECKING_SUCCESS";
export const AUTH_REGISTER_EMAIL_CHECKING_FAIL = "AUTH_REGISTER_EMAIL_CHECKING_FAIL";

export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT_START = "AUTH_LOGOUT_START";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL";

export const AUTH_LOGOUT_INTENDED_SUCCESS = "AUTH_LOGOUT_INTENDED_SUCCESS";

export const SIGN_IN_START = "SIGN_IN_START";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_SUCCESS_WITH_WAIT = "SIGN_IN_SUCCESS_WITH_WAIT";
export const SIGN_IN_FAIL = "SIGN_IN_FAIL";

export const AUTH_CHECK_SUCCESS = "AUTH_CHECK_SUCCESS";
export const AUTH_SET_REDIRECT_URL = "AUTH_SET_REDIRECT_URL";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const CREATE_ACCOUNT_START = "CREATE_ACCOUNT_START";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAIL = "CREATE_ACCOUNT_FAIL";

export const GET_RESET_PASSWORD_START = "GET_RESET_PASSWORD_START";
export const GET_RESET_PASSWORD_SUCCESS = "GET_RESET_PASSWORD_SUCCESS";
export const GET_RESET_PASSWORD_FAIL = "GET_RESET_PASSWORD_FAIL";

export const PROCESS_RESET_PASSWORD_START = "PROCESS_RESET_PASSWORD_START";
export const PROCESS_RESET_PASSWORD_SUCCESS = "PROCESS_RESET_PASSWORD_SUCCESS";
export const PROCESS_RESET_PASSWORD_FAIL = "PROCESS_RESET_PASSWORD_FAIL";

export const GET_JOBS_START = "GET_JOBS_START";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_FAIL = "GET_JOBS_FAIL";

export const GET_SAMPLE_JOBS_START = "GET_SAMPLE_JOBS_START";
export const GET_SAMPLE_JOBS_SUCCESS = "GET_SAMPLE_JOBS_SUCCESS";
export const GET_SAMPLE_JOBS_FAIL = "GET_SAMPLE_JOBS_FAIL";

export const GET_JOB_START = "GET_JOB_START";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
export const GET_JOB_FAIL = "GET_JOB_FAIL";

export const PRINT_JOB_REPORT_START = "PRINT_JOB_REPORT_START";
export const PRINT_JOB_REPORT_SUCCESS = "PRINT_JOB_REPORT_SUCCESS";
export const PRINT_JOB_REPORT_FAIL = "PRINT_JOB_REPORT_FAIL";

export const PRINT_JOB_REPORTS_START = "PRINT_JOB_REPORTS_START";
export const PRINT_JOB_REPORTS_SUCCESS = "PRINT_JOB_REPORTS_SUCCESS";
export const PRINT_JOB_REPORTS_FAIL = "PRINT_JOB_REPORTS_FAIL";

export const CREATE_JOB_START = "CREATE_JOB_START";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_FAIL = "CREATE_JOB_FAIL";

export const UPDATE_JOB_START = "UPDATE_JOB_START";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_FAIL = "UPDATE_JOB_FAIL";

export const UPDATE_JOB_BY_NOTIFICATION = "UPDATE_JOB_BY_NOTIFICATION";

export const UPDATE_JOB_NOTE_START = "UPDATE_JOB_NOTE_START";
export const UPDATE_JOB_NOTE_SUCCESS = "UPDATE_JOB_NOTE_SUCCESS";
export const UPDATE_JOB_NOTE_FAIL = "UPDATE_JOB_NOTE_FAIL";

export const UPDATE_JOB_SKILL_ATTRIBUTES_START = "UPDATE_JOB_SKILL_ATTRIBUTES_START";
export const UPDATE_JOB_SKILL_ATTRIBUTES_SUCCESS = "UPDATE_JOB_SKILL_ATTRIBUTES_SUCCESS";
export const UPDATE_JOB_SKILL_ATTRIBUTES_FAIL = "UPDATE_JOB_SKILL_ATTRIBUTES_FAIL";

export const DELETE_JOB_START = "DELETE_JOB_START";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAIL = "DELETE_JOB_FAIL";

export const COPY_JOB_START = "COPY_JOB_START";
export const COPY_JOB_SUCCESS = "COPY_JOB_SUCCESS";
export const COPY_JOB_FAIL = "COPY_JOB_FAIL";

export const COPY_JOBS_START = "COPY_JOBS_START";
export const COPY_JOBS_SUCCESS = "COPY_JOBS_SUCCESS";
export const COPY_JOBS_FAIL = "COPY_JOBS_FAIL";

export const GET_SENIORITY_VALUES_START = "GET_SENIORITY_VALUES_START";
export const GET_SENIORITY_VALUES_SUCCESS = "GET_SENIORITY_VALUES_SUCCESS";
export const GET_SENIORITY_VALUES_FAIL = "GET_SENIORITY_VALUES_FAIL";

export const GET_CONTRACT_TYPE_VALUES_START = "GET_CONTRACT_TYPE_VALUES_START";
export const GET_CONTRACT_TYPE_VALUES_SUCCESS = "GET_CONTRACT_TYPE_VALUES_SUCCESS";
export const GET_CONTRACT_TYPE_VALUES_FAIL = "GET_CONTRACT_TYPE_VALUES_FAIL";

export const GET_YEAR_RANGE_JOB_VALUES_START = "GET_YEAR_RANGE_JOB_VALUES_START";
export const GET_YEAR_RANGE_JOB_VALUES_SUCCESS = "GET_YEAR_RANGE_JOB_VALUES_SUCCESS";
export const GET_YEAR_RANGE_JOB_VALUES_FAIL = "GET_YEAR_RANGE_JOB_VALUES_FAIL";

export const GET_YEAR_RANGE_ORG_VALUES_START = "GET_YEAR_RANGE_ORG_VALUES_START";
export const GET_YEAR_RANGE_ORG_VALUES_SUCCESS = "GET_YEAR_RANGE_ORG_VALUES_SUCCESS";
export const GET_YEAR_RANGE_ORG_VALUES_FAIL = "GET_YEAR_RANGE_ORG_VALUES_FAIL";

export const GET_MY_PROFILE_TYPES_START = "GET_MY_PROFILE_TYPES_START";
export const GET_MY_PROFILE_TYPES_SUCCESS = "GET_MY_PROFILE_TYPES_SUCCESS";
export const GET_MY_PROFILE_TYPES_FAIL = "GET_MY_PROFILE_TYPES_FAIL";

export const GET_MANAGED_PEOPLE_PROFILE_TYPES_START = "GET_MANAGED_PEOPLE_PROFILE_TYPES_START";
export const GET_MANAGED_PEOPLE_PROFILE_TYPES_SUCCESS = "GET_MANAGED_PEOPLE_PROFILE_TYPES_SUCCESS";
export const GET_MANAGED_PEOPLE_PROFILE_TYPES_FAIL = "GET_MANAGED_PEOPLE_PROFILE_TYPES_FAIL";

export const GET_MY_PROFILE_VERSIONS_START = "GET_MY_PROFILE_VERSIONS_START";
export const GET_MY_PROFILE_VERSIONS_SUCCESS = "GET_MY_PROFILE_VERSIONS_SUCCESS";
export const GET_MY_PROFILE_VERSIONS_FAIL = "GET_MY_PROFILE_VERSIONS_FAIL";

export const GET_MANAGED_PEOPLE_PROFILE_VERSIONS_START = "GET_MANAGED_PEOPLE_PROFILE_VERSIONS_START";
export const GET_MANAGED_PEOPLE_PROFILE_VERSIONS_SUCCESS = "GET_MANAGED_PEOPLE_PROFILE_VERSIONS_SUCCESS";
export const GET_MANAGED_PEOPLE_PROFILE_VERSIONS_FAIL = "GET_MANAGED_PEOPLE_PROFILE_VERSIONS_FAIL";

export const GET_MY_SKILL_PROFILE_START = "GET_MY_SKILL_PROFILE_START";
export const GET_MY_SKILL_PROFILE_SUCCESS = "GET_MY_SKILL_PROFILE_SUCCESS";
export const GET_MY_SKILL_PROFILE_FAIL = "GET_MY_SKILL_PROFILE_FAIL";

export const GET_MANAGED_PEOPLE_SKILL_PROFILE_START = "GET_MANAGED_PEOPLE_SKILL_PROFILE_START";
export const GET_MANAGED_PEOPLE_SKILL_PROFILE_SUCCESS = "GET_MANAGED_PEOPLE_SKILL_PROFILE_SUCCESS";
export const GET_MANAGED_PEOPLE_SKILL_PROFILE_FAIL = "GET_MANAGED_PEOPLE_SKILL_PROFILE_FAIL";

export const GET_MY_LOR_PROFILE_START = "GET_MY_LOR_PROFILE_START";
export const GET_MY_LOR_PROFILE_SUCCESS = "GET_MY_LOR_PROFILE_SUCCESS";
export const GET_MY_LOR_PROFILE_FAIL = "GET_MY_LOR_PROFILE_FAIL";

export const GET_MANAGED_PEOPLE_LOR_PROFILE_START = "GET_MANAGED_PEOPLE_LOR_PROFILE_START";
export const GET_MANAGED_PEOPLE_LOR_PROFILE_SUCCESS = "GET_MANAGED_PEOPLE_LOR_PROFILE_SUCCESS";
export const GET_MANAGED_PEOPLE_LOR_PROFILE_FAIL = "GET_MANAGED_PEOPLE_LOR_PROFILE_FAIL";

export const PRINT_MY_PROFILE_START = "PRINT_MY_PROFILE_START";
export const PRINT_MY_PROFILE_SUCCESS = "PRINT_MY_PROFILE_SUCCESS";
export const PRINT_MY_PROFILE_FAIL = "PRINT_MY_PROFILE_FAIL";

export const PRINT_MANAGED_PEOPLE_PROFILE_START = "PRINT_MANAGED_PEOPLE_PROFILE_START";
export const PRINT_MANAGED_PEOPLE_PROFILE_SUCCESS = "PRINT_MANAGED_PEOPLE_PROFILE_SUCCESS";
export const PRINT_MANAGED_PEOPLE_PROFILE_FAIL = "PRINT_MANAGED_PEOPLE_PROFILE_FAIL";

export const GENERATE_PERSONAL_DATA_EXCEL_START = "GENERATE_PERSONAL_DATA_EXCEL_START";
export const GENERATE_PERSONAL_DATA_EXCEL_SUCCESS = "GENERATE_PERSONAL_DATA_EXCEL_SUCCESS";
export const GENERATE_PERSONAL_DATA_EXCEL_FAIL = "GENERATE_PERSONAL_DATA_EXCEL_FAIL";

export const EMAIL_MY_PROFILE_REPORT_START = "EMAIL_MY_PROFILE_REPORT_START";
export const EMAIL_MY_PROFILE_REPORT_SUCCESS = "EMAIL_MY_PROFILE_REPORT_SUCCESS";
export const EMAIL_MY_PROFILE_REPORT_FAIL = "EMAIL_MY_PROFILE_REPORT_FAIL";

export const UPDATE_SKILL_PROFILES_START = "UPDATE_SKILL_PROFILES_START";
export const UPDATE_SKILL_PROFILES_SUCCESS = "UPDATE_SKILL_PROFILES_SUCCESS";
export const UPDATE_SKILL_PROFILES_FAIL = "UPDATE_SKILL_PROFILES_FAIL";
export const CLEAR_UPDATE_SKILL_PROFILES = "CLEAR_UPDATE_SKILL_PROFILES";

export const SUBMIT_UPDATE_PROFILE_SUGGESTION_START = "SUBMIT_UPDATE_PROFILE_SUGGESTION_START";
export const SUBMIT_UPDATE_PROFILE_SUGGESTION_SUCCESS = "SUBMIT_UPDATE_PROFILE_SUGGESTION_SUCCESS";
export const SUBMIT_UPDATE_PROFILE_SUGGESTION_FAIL = "SUBMIT_UPDATE_PROFILE_SUGGESTION_FAIL";

export const ACCEPT_UPDATE_PROFILE_SUGGESTION_START = "ACCEPT_UPDATE_PROFILE_SUGGESTION_START";
export const ACCEPT_UPDATE_PROFILE_SUGGESTION_SUCCESS = "ACCEPT_UPDATE_PROFILE_SUGGESTION_SUCCESS";
export const ACCEPT_UPDATE_PROFILE_SUGGESTION_FAIL = "ACCEPT_UPDATE_PROFILE_SUGGESTION_FAIL";

export const REJECT_UPDATE_PROFILE_SUGGESTION_START = "REJECT_UPDATE_PROFILE_SUGGESTION_START";
export const REJECT_UPDATE_PROFILE_SUGGESTION_SUCCESS = "REJECT_UPDATE_PROFILE_SUGGESTION_SUCCESS";
export const REJECT_UPDATE_PROFILE_SUGGESTION_FAIL = "REJECT_UPDATE_PROFILE_SUGGESTION_FAIL";

export const UPDATE_ENDORSED_STATUS_START = "UPDATE_ENDORSED_STATUS_START";
export const UPDATE_ENDORSED_STATUS_SUCCESS = "UPDATE_ENDORSED_STATUS_SUCCESS";
export const UPDATE_ENDORSED_STATUS_FAIL = "UPDATE_ENDORSED_STATUS_FAIL";

export const CLEAR_MANAGE_PEOPLE_ENDORSED_STATUS = "CLEAR_MANAGE_PEOPLE_ENDORSED_STATUS";
export const UPDATE_MANAGE_PEOPLE_ENDORSED_STATUS = "UPDATE_MANAGE_PEOPLE_ENDORSED_STATUS";

export const COPY_ENDORSED_PROFILES_START = "COPY_ENDORSED_PROFILES_START";
export const COPY_ENDORSED_PROFILES_SUCCESS = "COPY_ENDORSED_PROFILES_SUCCESS";
export const COPY_ENDORSED_PROFILES_FAIL = "COPY_ENDORSED_PROFILES_FAIL";

export const COPY_PROFILE_START = "COPY_PROFILE_START";
export const COPY_PROFILE_SUCCESS = "COPY_PROFILE_SUCCESS";
export const COPY_PROFILE_FAIL = "COPY_PROFILE_FAIL";

export const CLEAR_COPY_ENDORSED_NOTIF = "CLEAR_COPY_ENDORSED_NOTIF";

export const UPDATE_MY_SKILL_PROFILE_COMMENT_START = "UPDATE_MY_SKILL_PROFILE_COMMENT_START";
export const UPDATE_MY_SKILL_PROFILE_COMMENT_SUCCESS = "UPDATE_MY_SKILL_PROFILE_COMMENT_SUCCESS";
export const UPDATE_MY_SKILL_PROFILE_COMMENT_FAIL = "UPDATE_MY_SKILL_PROFILE_COMMENT_FAIL";

export const UPDATE_MANAGED_PEOPLE_SKILL_PROFILE_COMMENT_START = "UPDATE_MANAGED_PEOPLE_SKILL_PROFILE_COMMENT_START";
export const UPDATE_MANAGED_PEOPLE_SKILL_PROFILE_COMMENT_SUCCESS = "UPDATE_MANAGED_PEOPLE_SKILL_PROFILE_COMMENT_SUCCESS";
export const UPDATE_MANAGED_PEOPLE_SKILL_PROFILE_COMMENT_FAIL = "UPDATE_MANAGED_PEOPLE_SKILL_PROFILE_COMMENT_FAIL";

export const UPDATE_MY_LOR_PROFILE_COMMENT_START = "UPDATE_MY_LOR_PROFILE_COMMENT_START";
export const UPDATE_MY_LOR_PROFILE_COMMENT_SUCCESS = "UPDATE_MY_LOR_PROFILE_COMMENT_SUCCESS";
export const UPDATE_MY_LOR_PROFILE_COMMENT_FAIL = "UPDATE_MY_LOR_PROFILE_COMMENT_FAIL";

export const UPDATE_MANAGED_PEOPLE_LOR_PROFILE_COMMENT_START = "UPDATE_MANAGED_PEOPLE_LOR_PROFILE_COMMENT_START";
export const UPDATE_MANAGED_PEOPLE_LOR_PROFILE_COMMENT_SUCCESS = "UPDATE_MANAGED_PEOPLE_LOR_PROFILE_COMMENT_SUCCESS";
export const UPDATE_MANAGED_PEOPLE_LOR_PROFILE_COMMENT_FAIL = "UPDATE_MANAGED_PEOPLE_LOR_PROFILE_COMMENT_FAIL";

export const GET_MY_JOB_MATCH_TYPES_START = "GET_MY_JOB_MATCH_TYPES_START";
export const GET_MY_JOB_MATCH_TYPES_SUCCESS = "GET_MY_JOB_MATCH_TYPES_SUCCESS";
export const GET_MY_JOB_MATCH_TYPES_FAIL = "GET_MY_JOB_MATCH_TYPES_FAIL";

export const GET_MY_JOB_MATCHES_START = "GET_MY_JOB_MATCHES_START";
export const GET_MY_JOB_MATCHES_SUCCESS = "GET_MY_JOB_MATCHES_SUCCESS";
export const GET_MY_JOB_MATCHES_FAIL = "GET_MY_JOB_MATCHES_FAIL";

export const GET_MANAGED_PEOPLE_JOB_MATCHES_START = "GET_MANAGED_PEOPLE_JOB_MATCHES_START";
export const GET_MANAGED_PEOPLE_JOB_MATCHES_SUCCESS = "GET_MANAGED_PEOPLE_JOB_MATCHES_SUCCESS";
export const GET_MANAGED_PEOPLE_JOB_MATCHES_FAIL = "GET_MANAGED_PEOPLE_JOB_MATCHES_FAIL";

export const UPDATE_MY_JOB_MATCHES_START = "UPDATE_MY_JOB_MATCHES_START";
export const UPDATE_MY_JOB_MATCHES_SUCCESS = "UPDATE_MY_JOB_MATCHES_SUCCESS";
export const UPDATE_MY_JOB_MATCHES_FAIL = "UPDATE_MY_JOB_MATCHES_FAIL";

export const UPDATE_MANAGED_PEOPLE_JOB_MATCHES_START = "UPDATE_MANAGED_PEOPLE_JOB_MATCHES_START";
export const UPDATE_MANAGED_PEOPLE_JOB_MATCHES_SUCCESS = "UPDATE_MANAGED_PEOPLE_JOB_MATCHES_SUCCESS";
export const UPDATE_MANAGED_PEOPLE_JOB_MATCHES_FAIL = "UPDATE_MANAGED_PEOPLE_JOB_MATCHES_FAIL";

export const GET_JOB_PROFILES_VERSIONS_START = "GET_JOB_PROFILES_VERSIONS_START";
export const GET_JOB_PROFILES_VERSIONS_SUCCESS = "GET_JOB_PROFILES_VERSIONS_SUCCESS";
export const GET_JOB_PROFILES_VERSIONS_FAIL = "GET_JOB_PROFILES_VERSIONS_FAIL";

export const GET_JOB_SKILL_PROFILES_START = "GET_JOB_SKILL_PROFILES_START";
export const GET_JOB_SKILL_PROFILES_SUCCESS = "GET_JOB_SKILL_PROFILES_SUCCESS";
export const GET_JOB_SKILL_PROFILES_FAIL = "GET_JOB_SKILL_PROFILES_FAIL";

export const GET_JOB_LOR_PROFILES_START = "GET_JOB_LOR_PROFILES_START";
export const GET_JOB_LOR_PROFILES_SUCCESS = "GET_JOB_LOR_PROFILES_SUCCESS";
export const GET_JOB_LOR_PROFILES_FAIL = "GET_JOB_LOR_PROFILES_FAIL";

export const UPDATE_JOB_SKILL_PROFILES_START = "UPDATE_JOB_SKILL_PROFILES_START";
export const UPDATE_JOB_SKILL_PROFILES_SUCCESS = "UPDATE_JOB_SKILL_PROFILES_SUCCESS";
export const UPDATE_JOB_SKILL_PROFILES_FAIL = "UPDATE_JOB_SKILL_PROFILES_FAIL";
export const CLEAR_UPDATE_JOB_SKILL_PROFILES = "CLEAR_UPDATE_JOB_SKILL_PROFILES";

export const GET_JOB_SKILL_ATTRIBUTES_START = "GET_JOB_SKILL_ATTRIBUTES_START";
export const GET_JOB_SKILL_ATTRIBUTES_SUCCESS = "GET_JOB_SKILL_ATTRIBUTES_SUCCESS";
export const GET_JOB_SKILL_ATTRIBUTES_FAIL = "GET_JOB_SKILL_ATTRIBUTES_FAIL";

export const GET_MANAGED_PEOPLE_JOB_SKILL_PROFILES_START = "GET_MANAGED_PEOPLE_JOB_SKILL_PROFILES_START";
export const GET_MANAGED_PEOPLE_JOB_SKILL_PROFILES_SUCCESS = "GET_MANAGED_PEOPLE_JOB_SKILL_PROFILES_SUCCESS";
export const GET_MANAGED_PEOPLE_JOB_SKILL_PROFILES_FAIL = "GET_MANAGED_PEOPLE_JOB_SKILL_PROFILES_FAIL";

export const GET_MANAGED_PEOPLE_JOB_LOR_PROFILES_START = "GET_MANAGED_PEOPLE_JOB_LOR_PROFILES_START";
export const GET_MANAGED_PEOPLE_JOB_LOR_PROFILES_SUCCESS = "GET_MANAGED_PEOPLE_JOB_LOR_PROFILES_SUCCESS";
export const GET_MANAGED_PEOPLE_JOB_LOR_PROFILES_FAIL = "GET_MANAGED_PEOPLE_JOB_LOR_PROFILES_FAIL";

export const GET_MY_ACTIONS_START = "GET_MY_ACTIONS_START";
export const GET_MY_ACTIONS_SUCCESS = "GET_MY_ACTIONS_SUCCESS";
export const GET_MY_ACTIONS_FAIL = "GET_MY_ACTIONS_FAIL";

export const GET_MANAGED_PEOPLE_ACTIONS_START = "GET_MANAGED_PEOPLE_ACTIONS_START";
export const GET_MANAGED_PEOPLE_ACTIONS_SUCCESS = "GET_MANAGED_PEOPLE_ACTIONS_SUCCESS";
export const GET_MANAGED_PEOPLE_ACTIONS_FAIL = "GET_MANAGED_PEOPLE_ACTIONS_FAIL";

export const CREATE_ACTION_START = "CREATE_ACTION_START";
export const CREATE_ACTION_SUCCESS = "CREATE_ACTION_SUCCESS";
export const CREATE_ACTION_FAIL = "CREATE_ACTION_FAIL";

export const UPDATE_ACTION_START = "UPDATE_ACTION_START";
export const UPDATE_ACTION_SUCCESS = "UPDATE_ACTION_SUCCESS";
export const UPDATE_ACTION_FAIL = "UPDATE_ACTION_FAIL";

export const DELETE_ACTION_START = "DELETE_ACTION_START";
export const DELETE_ACTION_SUCCESS = "DELETE_ACTION_SUCCESS";
export const DELETE_ACTION_FAIL = "DELETE_ACTION_FAIL";

export const PRINT_MY_ACTION_REPORT_START = "PRINT_MY_ACTION_REPORT_START";
export const PRINT_MY_ACTION_REPORT_SUCCESS = "PRINT_MY_ACTION_REPORT_SUCCESS";
export const PRINT_MY_ACTION_REPORT_FAIL = "PRINT_MY_ACTION_REPORT_FAIL";

export const CREATE_MANAGED_PEOPLE_ACTION_START = "CREATE_MANAGED_PEOPLE_ACTION_START";
export const CREATE_MANAGED_PEOPLE_ACTION_SUCCESS = "CREATE_MANAGED_PEOPLE_ACTION_SUCCESS";
export const CREATE_MANAGED_PEOPLE_ACTION_FAIL = "CREATE_MANAGED_PEOPLE_ACTION_FAIL";

export const UPDATE_MANAGED_PEOPLE_ACTION_START = "UPDATE_MANAGED_PEOPLE_ACTION_START";
export const UPDATE_MANAGED_PEOPLE_ACTION_SUCCESS = "UPDATE_MANAGED_PEOPLE_ACTION_SUCCESS";
export const UPDATE_MANAGED_PEOPLE_ACTION_FAIL = "UPDATE_MANAGED_PEOPLE_ACTION_FAIL";

export const DELETE_MANAGED_PEOPLE_ACTION_START = "DELETE_MANAGED_PEOPLE_ACTION_START";
export const DELETE_MANAGED_PEOPLE_ACTION_SUCCESS = "DELETE_MANAGED_PEOPLE_ACTION_SUCCESS";
export const DELETE_MANAGED_PEOPLE_ACTION_FAIL = "DELETE_MANAGED_PEOPLE_ACTION_FAIL";

export const PRINT_MANAGED_PEOPLE_ACTION_REPORT_START = "PRINT_MANAGED_PEOPLE_ACTION_REPORT_START";
export const PRINT_MANAGED_PEOPLE_ACTION_REPORT_SUCCESS = "PRINT_MANAGED_PEOPLE_ACTION_REPORT_SUCCESS";
export const PRINT_MANAGED_PEOPLE_ACTION_REPORT_FAIL = "PRINT_MANAGED_PEOPLE_ACTION_REPORT_FAIL";

export const SEND_UPDATED_ACTIONPLAN_NOTIFICATION_START = "SEND_UPDATED_ACTIONPLAN_NOTIFICATION_START";
export const SEND_UPDATED_ACTIONPLAN_NOTIFICATION_SUCCESS = "SEND_UPDATED_ACTIONPLAN_NOTIFICATION_SUCCESS";
export const SEND_UPDATED_ACTIONPLAN_NOTIFICATION_FAIL = "SEND_UPDATED_ACTIONPLAN_NOTIFICATION_FAIL";

export const GET_ACTION_TYPES_START = "GET_ACTION_TYPES_START";
export const GET_ACTION_TYPES_SUCCESS = "GET_ACTION_TYPES_SUCCESS";
export const GET_ACTION_TYPES_FAIL = "GET_ACTION_TYPES_FAIL";

export const GET_ACTION_SKILLS_START = "GET_ACTION_SKILLS_START";
export const GET_ACTION_SKILLS_SUCCESS = "GET_ACTION_SKILLS_SUCCESS";
export const GET_ACTION_SKILLS_FAIL = "GET_ACTION_SKILLS_FAIL";

export const GET_INTERVENTIONS_START = "GET_INTERVENTIONS_START";
export const GET_INTERVENTIONS_SUCCESS = "GET_INTERVENTIONS_SUCCESS";
export const GET_INTERVENTIONS_FAIL = "GET_INTERVENTIONS_FAIL";

export const UPDATE_INTERVENTION_HIDDEN_START = "UPDATE_INTERVENTION_HIDDEN_START";
export const UPDATE_INTERVENTION_HIDDEN_SUCCESS = "UPDATE_INTERVENTION_HIDDEN_SUCCESS";
export const UPDATE_INTERVENTION_HIDDEN_FAIL = "UPDATE_INTERVENTION_HIDDEN_FAIL";

export const GET_COMPANIES_START = "GET_COMPANIES_START";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL";

export const GET_DEFAULT_COMPANY_START = "GET_DEFAULT_COMPANY_START";
export const GET_DEFAULT_COMPANY_SUCCESS = "GET_DEFAULT_COMPANY_SUCCESS";
export const GET_DEFAULT_COMPANY_FAIL = "GET_DEFAULT_COMPANY_FAIL";

export const GET_COMPANY_BRANDING_LOGO_START = "GET_COMPANY_BRANDING_LOGO_START";
export const GET_COMPANY_BRANDING_LOGO_SUCCESS = "GET_COMPANY_BRANDING_LOGO_SUCCESS";
export const GET_COMPANY_BRANDING_LOGO_FAIL = "GET_COMPANY_BRANDING_LOGO_FAIL";

export const CREATE_COMPANY_START = "CREATE_COMPANY_START";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAIL = "CREATE_COMPANY_FAIL";

export const UPDATE_COMPANY_START = "UPDATE_COMPANY_START";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL";

export const GET_SELECTED_PROVIDER_COMPANIES_START = "GET_SELECTED_PROVIDER_COMPANIES_START";
export const GET_SELECTED_PROVIDER_COMPANIES_SUCCESS = "GET_SELECTED_PROVIDER_COMPANIES_SUCCESS";
export const GET_SELECTED_PROVIDER_COMPANIES_FAIL = "GET_SELECTED_PROVIDER_COMPANIES_FAIL";

export const GET_PROVIDER_COMPANIES_START = "GET_PROVIDER_COMPANIES_START";
export const GET_PROVIDER_COMPANIES_SUCCESS = "GET_PROVIDER_COMPANIES_SUCCESS";
export const GET_PROVIDER_COMPANIES_FAIL = "GET_PROVIDER_COMPANIES_FAIL";

export const GET_MY_SURVEYS_START = "GET_MY_SURVEYS_START";
export const GET_MY_SURVEYS_SUCCESS = "GET_MY_SURVEYS_SUCCESS";
export const GET_MY_SURVEYS_FAIL = "GET_MY_SURVEYS_FAIL";

export const MY_SURVEYS_SELECT_ROLE_START = "MY_SURVEYS_SELECT_ROLE_START";
export const MY_SURVEYS_SELECT_ROLE_SUCCESS = "MY_SURVEYS_SELECT_ROLE_SUCCESS";
export const MY_SURVEYS_SELECT_ROLE_FAIL = "MY_SURVEYS_SELECT_ROLE_FAIL";

export const GET_SURVEY_QUESTION_START = "GET_SURVEY_QUESTION_START";
export const GET_SURVEY_QUESTION_SUCCESS = "GET_SURVEY_QUESTION_SUCCESS";
export const GET_SURVEY_QUESTION_FAIL = "GET_SURVEY_QUESTION_FAIL";

export const ANSWER_QUESTION_START = "ANSWER_QUESTION_START";
export const ANSWER_QUESTION_SUCCESS = "ANSWER_QUESTION_SUCCESS";
export const ANSWER_QUESTION_FAIL = "ANSWER_QUESTION_FAIL";

export const SKIP_QUESTION_START = "SKIP_QUESTION_START";
export const SKIP_QUESTION_SUCCESS = "SKIP_QUESTION_SUCCESS";
export const SKIP_QUESTION_FAIL = "SKIP_QUESTION_FAIL";

export const COMPLETE_MY_SURVEYS_START = "COMPLETE_MY_SURVEYS_START";
export const COMPLETE_MY_SURVEYS_SUCCESS = "COMPLETE_MY_SURVEYS_SUCCESS";
export const COMPLETE_MY_SURVEYS_FAIL = "COMPLETE_MY_SURVEYS_FAIL";

export const RESET_MY_SURVEYS_START = "RESET_MY_SURVEYS_START";
export const RESET_MY_SURVEYS_SUCCESS = "RESET_MY_SURVEYS_SUCCESS";
export const RESET_MY_SURVEYS_FAIL = "RESET_MY_SURVEYS_FAIL";

export const GET_EVIDENCE_TYPES_START = "GET_EVIDENCE_TYPES_START";
export const GET_EVIDENCE_TYPES_SUCCESS = "GET_EVIDENCE_TYPES_SUCCESS";
export const GET_EVIDENCE_TYPES_FAIL = "GET_EVIDENCE_TYPES_FAIL";

export const GET_EVIDENCES_BYACCOUNTID_START = "GET_EVIDENCES_BYACCOUNTID_START";
export const GET_EVIDENCES_BYACCOUNTID_SUCCESS = "GET_EVIDENCES_BYACCOUNTID_SUCCESS";
export const GET_EVIDENCES_BYACCOUNTID_FAIL = "GET_EVIDENCES_BYACCOUNTID_FAIL";

export const GET_EVIDENCES_BY_MANAGE_PEOPLE_ACCOUNTID_START = "GET_EVIDENCES_BY_MANAGE_PEOPLE_ACCOUNTID_START";
export const GET_EVIDENCES_BY_MANAGE_PEOPLE_ACCOUNTID_SUCCESS = "GET_EVIDENCES_BY_MANAGE_PEOPLE_ACCOUNTID_SUCCESS";
export const GET_EVIDENCES_BY_MANAGE_PEOPLE_ACCOUNTID_FAIL = "GET_EVIDENCES_BY_MANAGE_PEOPLE_ACCOUNTID_FAIL";

export const ADD_EVIDENCE_START = "ADD_EVIDENCE_START";
export const ADD_EVIDENCE_SUCCESS = "ADD_EVIDENCE_SUCCESS";
export const ADD_EVIDENCE_FAIL = "ADD_EVIDENCE_FAIL";

export const ADD_MANAGED_PEOPLE_EVIDENCE_START = "ADD_MANAGED_PEOPLE_EVIDENCE_START";
export const ADD_MANAGED_PEOPLE_EVIDENCE_SUCCESS = "ADD_MANAGED_PEOPLE_EVIDENCE_SUCCESS";
export const ADD_MANAGED_PEOPLE_EVIDENCE_FAIL = "ADD_MANAGED_PEOPLE_EVIDENCE_FAIL";

export const UPDATE_EVIDENCE_START = "UPDATE_EVIDENCE_START";
export const UPDATE_EVIDENCE_SUCCESS = "UPDATE_EVIDENCE_SUCCESS";
export const UPDATE_EVIDENCE_FAIL = "UPDATE_EVIDENCE_FAIL";

export const UPDATE_MANAGED_PEOPLE_EVIDENCE_START = "UPDATE_MANAGED_PEOPLE_EVIDENCE_START";
export const UPDATE_MANAGED_PEOPLE_EVIDENCE_SUCCESS = "UPDATE_MANAGED_PEOPLE_EVIDENCE_SUCCESS";
export const UPDATE_MANAGED_PEOPLE_EVIDENCE_FAIL = "UPDATE_MANAGED_PEOPLE_EVIDENCE_FAIL";

export const DELETE_EVIDENCE_START = "DELETE_EVIDENCE_START";
export const DELETE_EVIDENCE_SUCCESS = "DELETE_EVIDENCE_SUCCESS";
export const DELETE_EVIDENCE_FAIL = "DELETE_EVIDENCE_FAIL";

export const DELETE_MANAGED_PEOPLE_EVIDENCE_START = "DELETE_MANAGED_PEOPLE_EVIDENCE_START";
export const DELETE_MANAGED_PEOPLE_EVIDENCE_SUCCESS = "DELETE_MANAGED_PEOPLE_EVIDENCE_SUCCESS";
export const DELETE_MANAGED_PEOPLE_EVIDENCE_FAIL = "DELETE_MANAGED_PEOPLE_EVIDENCE_FAIL";

export const DOWNLOAD_EVIDENCE_BYID_START = "DOWNLOAD_EVIDENCE_BYID_START";
export const DOWNLOAD_EVIDENCE_BYID_SUCCESS = "DOWNLOAD_EVIDENCE_BYID_SUCCESS";
export const DOWNLOAD_EVIDENCE_BYID_FAIL = "DOWNLOAD_EVIDENCE_BYID_FAIL";

export const DOWNLOAD_MANAGED_PEOPLE_EVIDENCE_BYID_START = "DOWNLOAD_MANAGED_PEOPLE_EVIDENCE_BYID_START";
export const DOWNLOAD_MANAGED_PEOPLE_EVIDENCE_BYID_SUCCESS = "DOWNLOAD_MANAGED_PEOPLE_EVIDENCE_BYID_SUCCESS";
export const DOWNLOAD_MANAGED_PEOPLE_EVIDENCE_BYID_FAIL = "DOWNLOAD_MANAGED_PEOPLE_EVIDENCE_BYID_FAIL";

export const GET_PERSON_MYPEOPLES_START = "GET_PERSON_MYPEOPLES_START";
export const GET_PERSON_MYPEOPLES_SUCCESS = "GET_PERSON_MYPEOPLES_SUCCESS";
export const GET_PERSON_MYPEOPLES_FAIL = "GET_PERSON_MYPEOPLES_FAIL";

export const GET_PERSON_MYPEOPLE_BYEMAIL_START = "GET_PERSON_MYPEOPLE_BYEMAIL_START";
export const GET_PERSON_MYPEOPLE_BYEMAIL_SUCCESS = "GET_PERSON_MYPEOPLE_BYEMAIL_SUCCESS";
export const GET_PERSON_MYPEOPLE_BYEMAIL_FAIL = "GET_PERSON_MYPEOPLE_BYEMAIL_FAIL";

export const GET_PERSON_MYPEOPLES_TEAMANALYTIC_START = "GET_PERSON_MYPEOPLES_TEAMANALYTIC_START";
export const GET_PERSON_MYPEOPLES_TEAMANALYTIC_SUCCESS = "GET_PERSON_MYPEOPLES_TEAMANALYTIC_SUCCESS";
export const GET_PERSON_MYPEOPLES_TEAMANALYTIC_FAIL = "GET_PERSON_MYPEOPLES_TEAMANALYTIC_FAIL";

export const GET_PERSON_MYLEADERS_START = "GET_PERSON_MYLEADERS_START";
export const GET_PERSON_MYLEADERS_SUCCESS = "GET_PERSON_MYLEADERS_SUCCESS";
export const GET_PERSON_MYLEADERS_FAIL = "GET_PERSON_MYLEADERS_FAIL";

export const PERSON_FORGET_ME_START = "PERSON_FORGET_ME_START";
export const PERSON_FORGET_ME_SUCCESS = "PERSON_FORGET_ME_SUCCESS";
export const PERSON_FORGET_ME_FAIL = "PERSON_FORGET_ME_FAIL";

export const MYPEOPLES_SET_MANAGED_RELATIONSHIP = "MYPEOPLES_SET_MANAGED_RELATIONSHIP";
export const MYPEOPLES_CLEAR_MANAGED_RELATIONSHIP = "MYPEOPLES_CLEAR_MANAGED_RELATIONSHIP";

export const APPROVE_MYPEOPLE_PROFILE_START = "APPROVE_MYPEOPLE_PROFILE_START";
export const APPROVE_MYPEOPLE_PROFILE_SUCCESS = "APPROVE_MYPEOPLE_PROFILE_SUCCESS";
export const APPROVE_MYPEOPLE_PROFILE_FAIL = "APPROVE_MYPEOPLE_PROFILE_FAIL";

export const POSTPONE_APPROVE_MYPEOPLE_PROFILE_START = "POSTPONE_APPROVE_MYPEOPLE_PROFILE_START";
export const POSTPONE_APPROVE_MYPEOPLE_PROFILE_SUCCESS = "POSTPONE_APPROVE_MYPEOPLE_PROFILE_SUCCESS";
export const POSTPONE_APPROVE_MYPEOPLE_PROFILE_FAIL = "POSTPONE_APPROVE_MYPEOPLE_PROFILE_FAIL";

export const GET_LANGUAGES_START = "GET_LANGUAGES_START";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_FAIL = "GET_LANGUAGES_FAIL";

export const SET_LANGUAGE = "SET_LANGUAGE";

export const GET_CONTENT_TRANSLATIONS_START = "GET_CONTENT_TRANSLATIONS_START";
export const GET_CONTENT_TRANSLATIONS_SUCCESS = "GET_CONTENT_TRANSLATIONS_SUCCESS";
export const GET_CONTENT_TRANSLATIONS_FAIL = "GET_CONTENT_TRANSLATIONS_FAIL";

export const GENERATE_ACTIVITYSTATEMENT_START = "GENERATE_ACTIVITYSTATEMENT_START";
export const GENERATE_ACTIVITYSTATEMENT_SUCCESS = "GENERATE_ACTIVITYSTATEMENT_SUCCESS";
export const GENERATE_ACTIVITYSTATEMENT_FAIL = "GENERATE_ACTIVITYSTATEMENT_FAIL";

export const SET_JOBIMPORT_SESSIONID = "SET_JOBIMPORT_SESSIONID";

export const EXPORT_JOBS_DATA_START = "EXPORT_JOBS_DATA_START";
export const EXPORT_JOBS_DATA_SUCCESS = "EXPORT_JOBS_DATA_SUCCESS";
export const EXPORT_JOBS_DATA_FAIL = "EXPORT_JOBS_DATA_FAIL";

export const IMPORT_JOBS_DATA_START = "IMPORT_JOBS_DATA_START";
export const IMPORT_JOBS_DATA_SUCCESS = "IMPORT_JOBS_DATA_SUCCESS";
export const IMPORT_JOBS_DATA_FAIL = "IMPORT_JOBS_DATA_FAIL";

export const UPDATE_IMPORT_JOB_STATUS = "UPDATE_IMPORT_JOB_STATUS";

export const GET_JOB_IMPORT_QUEUE_START = "GET_JOB_IMPORT_QUEUE_START";
export const GET_JOB_IMPORT_QUEUE_SUCCESS = "GET_JOB_IMPORT_QUEUE_SUCCESS";
export const GET_JOB_IMPORT_QUEUE_FAIL = "GET_JOB_IMPORT_QUEUE_FAIL";

export const GET_JOB_MATCH_PERSONS_START = "GET_JOB_MATCH_PERSONS_START";
export const GET_JOB_MATCH_PERSONS_SUCCESS = "GET_JOB_MATCH_PERSONS_SUCCESS";
export const GET_JOB_MATCH_PERSONS_FAIL = "GET_JOB_MATCH_PERSONS_FAIL";

export const ASSIGN_JOB_MATCH_PERSON_START = "ASSIGN_JOB_MATCH_PERSON_START";
export const ASSIGN_JOB_MATCH_PERSON_SUCCESS = "ASSIGN_JOB_MATCH_PERSON_SUCCESS";
export const ASSIGN_JOB_MATCH_PERSON_FAIL = "ASSIGN_JOB_MATCH_PERSON_FAIL";

export const EXPORT_JOB_MATCHES_DATA_START = "EXPORT_JOB_MATCHES_DATA_START";
export const EXPORT_JOB_MATCHES_DATA_SUCCESS = "EXPORT_JOB_MATCHES_DATA_SUCCESS";
export const EXPORT_JOB_MATCHES_DATA_FAIL = "EXPORT_JOB_MATCHES_DATA_FAIL";

export const IMPORT_JOB_MATCHES_DATA_START = "IMPORT_JOB_MATCHES_DATA_START";
export const IMPORT_JOB_MATCHES_DATA_SUCCESS = "IMPORT_JOB_MATCHES_DATA_SUCCESS";
export const IMPORT_JOB_MATCHES_DATA_FAIL = "IMPORT_JOB_MATCHES_DATA_FAIL";

export const ADD_SKILL_ATTRIBUTE_DATA_START = "ADD_SKILL_ATTRIBUTE_DATA_START";
export const ADD_SKILL_ATTRIBUTE_DATA_SUCCESS = "ADD_SKILL_ATTRIBUTE_DATA_SUCCESS";
export const ADD_SKILL_ATTRIBUTE_DATA_FAIL = "ADD_SKILL_ATTRIBUTE_DATA_FAIL";

export const UPDATE_SKILL_ATTRIBUTE_DATA_START = "UPDATE_SKILL_ATTRIBUTE_DATA_START";
export const UPDATE_SKILL_ATTRIBUTE_DATA_SUCCESS = "UPDATE_SKILL_ATTRIBUTE_DATA_SUCCESS";
export const UPDATE_SKILL_ATTRIBUTE_DATA_FAIL = "UPDATE_SKILL_ATTRIBUTE_DATA_FAIL";

export const UPDATE_SKILL_SET_ISHIDDEN_START = "UPDATE_SKILL_SET_ISHIDDEN_START";
export const UPDATE_SKILL_SET_ISHIDDEN_SUCCESS = "UPDATE_SKILL_SET_ISHIDDEN_SUCCESS";
export const UPDATE_SKILL_SET_ISHIDDEN_FAIL = "UPDATE_SKILL_SET_ISHIDDEN_FAIL";

export const DELETE_SKILL_ATTRIBUTE_DATA_START = "DELETE_SKILL_ATTRIBUTE_DATA_START";
export const DELETE_SKILL_ATTRIBUTE_DATA_SUCCESS = "DELETE_SKILL_ATTRIBUTE_DATA_SUCCESS";
export const DELETE_SKILL_ATTRIBUTE_DATA_FAIL = "DELETE_SKILL_ATTRIBUTE_DATA_FAIL";

export const EXPORT_SKILL_ATTRIBUTES_DATA_START = "EXPORT_SKILL_ATTRIBUTES_DATA_START";
export const EXPORT_SKILL_ATTRIBUTES_DATA_SUCCESS = "EXPORT_SKILL_ATTRIBUTES_DATA_SUCCESS";
export const EXPORT_SKILL_ATTRIBUTES_DATA_FAIL = "EXPORT_SKILL_ATTRIBUTES_DATA_FAIL";

export const IMPORT_SKILL_ATTRIBUTES_DATA_START = "IMPORT_SKILL_ATTRIBUTES_DATA_START";
export const IMPORT_SKILL_ATTRIBUTES_DATA_SUCCESS = "IMPORT_SKILL_ATTRIBUTES_DATA_SUCCESS";
export const IMPORT_SKILL_ATTRIBUTES_DATA_FAIL = "IMPORT_SKILL_ATTRIBUTES_DATA_FAIL";

export const EXPORT_SKILLS_HIDDEN_DATA_START = "EXPORT_SKILLS_HIDDEN_DATA_START";
export const EXPORT_SKILLS_HIDDEN_DATA_SUCCESS = "EXPORT_SKILLS_HIDDEN_DATA_SUCCESS";
export const EXPORT_SKILLS_HIDDEN_DATA_FAIL = "EXPORT_SKILLS_HIDDEN_DATA_FAIL";

export const IMPORT_SKILLS_HIDDEN_DATA_START = "IMPORT_SKILLS_HIDDEN_DATA_START";
export const IMPORT_SKILLS_HIDDEN_DATA_SUCCESS = "IMPORT_SKILLS_HIDDEN_DATA_SUCCESS";
export const IMPORT_SKILLS_HIDDEN_DATA_FAIL = "IMPORT_SKILLS_HIDDEN_DATA_FAIL";

export const GET_CONFIG_SAML_ENABLED_STATUS_START = "GET_CONFIG_SAML_ENABLED_STATUS_START";
export const GET_CONFIG_SAML_ENABLED_STATUS_SUCCESS = "GET_CONFIG_SAML_ENABLED_STATUS_SUCCESS";
export const GET_CONFIG_SAML_ENABLED_STATUS_FAIL = "GET_CONFIG_SAML_ENABLED_STATUS_FAIL";

export const GET_CONFIG_CURR_ASSESSMENT_START = "GET_CONFIG_CURR_ASSESSMENT_START";
export const GET_CONFIG_CURR_ASSESSMENT_SUCCESS = "GET_CONFIG_CURR_ASSESSMENT_SUCCESS";
export const GET_CONFIG_CURR_ASSESSMENT_FAIL = "GET_CONFIG_CURR_ASSESSMENT_FAIL";

export const CLEAR_CONFIG_CURR_ASSESSMENT = "CLEAR_CONFIG_CURR_ASSESSMENT";

export const GET_CONTAINER_CONFIG_START = "GET_CONTAINER_CONFIG_START";
export const GET_CONTAINER_CONFIG_SUCCESS = "GET_CONTAINER_CONFIG_SUCCESS";
export const GET_CONTAINER_CONFIG_FAIL = "GET_CONTAINER_CONFIG_FAIL";

export const GET_CONTAINER_CONFIG_WITHOUT_LOADING_START = "GET_CONTAINER_CONFIG_WITHOUT_LOADING_START";
export const GET_CONTAINER_CONFIG_WITHOUT_LOADING_SUCCESS = "GET_CONTAINER_CONFIG_WITHOUT_LOADING_SUCCESS";
export const GET_CONTAINER_CONFIG_WITHOUT_LOADING_FAIL = "GET_CONTAINER_CONFIG_WITHOUT_LOADING_FAIL";

export const GET_ADMIN_CONFIG_START = "GET_ADMIN_CONFIG_START";
export const GET_ADMIN_CONFIG_SUCCESS = "GET_ADMIN_CONFIG_SUCCESS";
export const GET_ADMIN_CONFIG_FAIL = "GET_ADMIN_CONFIG_FAIL";

export const UPDATE_ADMIN_CONFIG_START = "UPDATE_ADMIN_CONFIG_START";
export const UPDATE_ADMIN_CONFIG_SUCCESS = "UPDATE_ADMIN_CONFIG_SUCCESS";
export const UPDATE_ADMIN_CONFIG_FAIL = "UPDATE_ADMIN_CONFIG_FAIL";

export const GET_CONTAINER_APP_SETTING_START = "GET_CONTAINER_APP_SETTING_START";
export const GET_CONTAINER_APP_SETTING_SUCCESS = "GET_CONTAINER_APP_SETTING_SUCCESS";
export const GET_CONTAINER_APP_SETTING_FAIL = "GET_CONTAINER_APP_SETTING_FAIL";

export const UPDATE_CONTAINER_APP_SETTING_START = "UPDATE_CONTAINER_APP_SETTING_START";
export const UPDATE_CONTAINER_APP_SETTING_SUCCESS = "UPDATE_CONTAINER_APP_SETTING_SUCCESS";
export const UPDATE_CONTAINER_APP_SETTING_FAIL = "UPDATE_CONTAINER_APP_SETTING_FAIL";

export const DOWNLOAD_POWERBIMODEL_START = "DOWNLOAD_POWERBIMODEL_START";
export const DOWNLOAD_POWERBIMODEL_SUCCESS = "DOWNLOAD_POWERBIMODEL_SUCCESS";
export const DOWNLOAD_POWERBIMODEL_FAIL = "DOWNLOAD_POWERBIMODEL_FAIL";

export const GET_EXTRAFRAMEWORK_LEVELS_START = "GET_EXTRAFRAMEWORK_LEVELS_START";
export const GET_EXTRAFRAMEWORK_LEVELS_SUCCESS = "GET_EXTRAFRAMEWORK_LEVELS_SUCCESS";
export const GET_EXTRAFRAMEWORK_LEVELS_FAIL = "GET_EXTRAFRAMEWORK_LEVELS_FAIL";

export const GET_EXTRAFRAMEWORK_CAPABILITIES_START = "GET_EXTRAFRAMEWORK_CAPABILITIES_START";
export const GET_EXTRAFRAMEWORK_CAPABILITIES_SUCCESS = "GET_EXTRAFRAMEWORK_CAPABILITIES_SUCCESS";
export const GET_EXTRAFRAMEWORK_CAPABILITIES_FAIL = "GET_EXTRAFRAMEWORK_CAPABILITIES_FAIL";

export const GET_MY_LATEST_BADGE_REQUEST_START = "GET_MY_LATEST_BADGE_REQUEST_START";
export const GET_MY_LATEST_BADGE_REQUEST_SUCCESS = "GET_MY_LATEST_BADGE_REQUEST_SUCCESS";
export const GET_MY_LATEST_BADGE_REQUEST_FAIL = "GET_MY_LATEST_BADGE_REQUEST_FAIL";

export const SUBMIT_BADGE_REQUEST_START = "SUBMIT_BADGE_REQUEST_START";
export const SUBMIT_BADGE_REQUEST_SUCCESS = "SUBMIT_BADGE_REQUEST_SUCCESS";
export const SUBMIT_BADGE_REQUEST_FAIL = "SUBMIT_BADGE_REQUEST_FAIL";

export const SUBMIT_ACTION_PROMOTION_START = "SUBMIT_ACTION_PROMOTION_START";
export const SUBMIT_ACTION_PROMOTION_SUCCESS = "SUBMIT_ACTION_PROMOTION_SUCCESS";
export const SUBMIT_ACTION_PROMOTION_FAIL = "SUBMIT_ACTION_PROMOTION_FAIL";

export const GET_PERSON_SKILL_COINS_START = "GET_PERSON_SKILL_COINS_START";
export const GET_PERSON_SKILL_COINS_SUCCESS = "GET_PERSON_SKILL_COINS_SUCCESS";
export const GET_PERSON_SKILL_COINS_FAIL = "GET_PERSON_SKILL_COINS_FAIL";

export const GET_ACTIVE_USER_COUNT_STATUS_START = "GET_ACTIVE_USER_COUNT_STATUS_START";
export const GET_ACTIVE_USER_COUNT_STATUS_SUCCESS = "GET_ACTIVE_USER_COUNT_STATUS_SUCCESS";
export const GET_ACTIVE_USER_COUNT_STATUS_FAIL = "GET_ACTIVE_USER_COUNT_STATUS_FAIL";

export const CLEAR_ACTIVE_USER_COUNT_STATUS = "CLEAR_ACTIVE_USER_COUNT_STATUS";

export const EXECUTE_INACTIVATION_USERS_LESS_LOGIN_START = "EXECUTE_INACTIVATION_USERS_LESS_LOGIN_START";
export const EXECUTE_INACTIVATION_USERS_LESS_LOGIN_SUCCESS = "EXECUTE_INACTIVATION_USERS_LESS_LOGIN_SUCCESS";
export const EXECUTE_INACTIVATION_USERS_LESS_LOGIN_FAIL = "EXECUTE_INACTIVATION_USERS_LESS_LOGIN_FAIL";

export const CLEAR_SAML_ENABLED_STATUS = "CLEAR_SAML_ENABLED_STATUS";

export const GET_JOB_FAMILIES_START = "GET_JOB_FAMILIES_START";
export const GET_JOB_FAMILIES_SUCCESS = "GET_JOB_FAMILIES_SUCCESS";
export const GET_JOB_FAMILIES_FAIL = "GET_JOB_FAMILIES_FAIL";

export const UPDATE_JOB_FAMILY_START = "UPDATE_JOB_FAMILY_START";
export const UPDATE_JOB_FAMILY_SUCCESS = "UPDATE_JOB_FAMILY_SUCCESS";
export const UPDATE_JOB_FAMILY_FAIL = "UPDATE_JOB_FAMILY_FAIL";

export const DELETE_JOB_FAMILY_START = "DELETE_JOB_FAMILY_START";
export const DELETE_JOB_FAMILY_SUCCESS = "DELETE_JOB_FAMILY_SUCCESS";
export const DELETE_JOB_FAMILY_FAIL = "DELETE_JOB_FAMILY_FAIL";

export const GET_COLUMN_DISPLAY_SETTINGS_START = "GET_COLUMN_DISPLAY_SETTINGS_START";
export const GET_COLUMN_DISPLAY_SETTINGS_SUCCESS = "GET_COLUMN_DISPLAY_SETTINGS_SUCCESS";
export const GET_COLUMN_DISPLAY_SETTINGS_FAIL = "GET_COLUMN_DISPLAY_SETTINGS_FAIL";

export const UPDATE_COLUMN_DISPLAY_SETTING_START = "UPDATE_COLUMN_DISPLAY_SETTING_START";
export const UPDATE_COLUMN_DISPLAY_SETTING_SUCCESS = "UPDATE_COLUMN_DISPLAY_SETTING_SUCCESS";
export const UPDATE_COLUMN_DISPLAY_SETTING_FAIL = "UPDATE_COLUMN_DISPLAY_SETTING_FAIL";

export const UPDATE_COLUMN_DISPLAY_SETTING_MOVE_START = "UPDATE_COLUMN_DISPLAY_SETTING_MOVE_START";
export const UPDATE_COLUMN_DISPLAY_SETTING_MOVE_SUCCESS = "UPDATE_COLUMN_DISPLAY_SETTING_MOVE_SUCCESS";
export const UPDATE_COLUMN_DISPLAY_SETTING_MOVE_FAIL = "UPDATE_COLUMN_DISPLAY_SETTING_MOVE_FAIL";

export const GET_PERSON_MENTOR_PREFERENCE_START = "GET_PERSON_MENTOR_PREFERENCE_START";
export const GET_PERSON_MENTOR_PREFERENCE_SUCCESS = "GET_PERSON_MENTOR_PREFERENCE_SUCCESS";
export const GET_PERSON_MENTOR_PREFERENCE_FAIL = "GET_PERSON_MENTOR_PREFERENCE_FAIL";

export const SUBMIT_PROPOSE_ACTION_START = "SUBMIT_PROPOSE_ACTION_START";
export const SUBMIT_PROPOSE_ACTION_SUCCESS = "SUBMIT_PROPOSE_ACTION_SUCCESS";
export const SUBMIT_PROPOSE_ACTION_FAIL = "SUBMIT_PROPOSE_ACTION_FAIL";

export const ACCEPT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_START = "ACCEPT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_START";
export const ACCEPT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_SUCCESS = "ACCEPT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_SUCCESS";
export const ACCEPT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_FAIL = "ACCEPT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_FAIL";

export const REJECT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_START = "REJECT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_START";
export const REJECT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_SUCCESS = "REJECT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_SUCCESS";
export const REJECT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_FAIL = "REJECT_UPDATE_PROFILE_FROM_ACTION_PROPOSAL_FAIL";

export const EXPORT_MIGRATION_IMPACT_ASSESSMENT_REPORTS_START = "EXPORT_MIGRATION_IMPACT_ASSESSMENT_REPORTS_START";
export const EXPORT_MIGRATION_IMPACT_ASSESSMENT_REPORTS_TOEMAIL_START = "EXPORT_MIGRATION_IMPACT_ASSESSMENT_REPORTS_TOEMAIL_START";
export const EXPORT_MIGRATION_IMPACT_ASSESSMENT_REPORTS_SUCCESS = "EXPORT_MIGRATION_IMPACT_ASSESSMENT_REPORTS_SUCCESS";
export const EXPORT_MIGRATION_IMPACT_ASSESSMENT_REPORTS_FAIL = "EXPORT_MIGRATION_IMPACT_ASSESSMENT_REPORTS_FAIL";
