import React from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import * as lorActions from "../../../store/actions/lorAction";
import changeInput from "../../../utils/changeInput";

class UpdateLor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showDialog: false,
            isUpdating: false,
            form: {
                name: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                lorCode: {
                    validation: {
                        required: true,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                category: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                categoryColor: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
                lorColor: {
                    validation: {
                        required: false,
                    },
                    value: "",
                    valid: true,
                    isValidFormat: true,
                    touched: false,
                },
            },
            isFormValid: true,
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
            this.setState({ showDialog: true });
            this.prepareFormState();
        }
    }

    prepareFormState = () => {
        const lor = this.props.lor;
        if (lor) {
            this.setState({
                form: {
                    name: {
                        validation: {
                            required: true,
                        },
                        value: lor.name,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    lorCode: {
                        validation: {
                            required: true,
                        },
                        value: lor.lorCode,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    category: {
                        validation: {
                            required: false,
                        },
                        value: lor.category,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    categoryColor: {
                        validation: {
                            required: false,
                        },
                        value: lor.categoryColor,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                    lorColor: {
                        validation: {
                            required: false,
                        },
                        value: lor.lorColor,
                        valid: true,
                        isValidFormat: true,
                        touched: false,
                    },
                },
                isFormValid: true,
            });
        }
    };

    togleDialogModal = () => {
        const showDialog = this.state.showDialog;
        this.setState({
            showDialog: !showDialog,
        });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        const updateState = changeInput(this.state.form, name, value);

        this.setState({
            form: updateState.form,
            isFormValid: updateState.valid,
        });
    };

    handlePerformSave = () => {
        const form = this.state.form;
        const lor = {
            name: form.name.value,
            lorCode: form.lorCode.value,
            category: form.category.value,
            categoryColor: form.category.value ? (form.categoryColor.value ? form.categoryColor.value : "#69849c") : null,
            lorColor: form.category.value ? (form.lorColor.value ? form.lorColor.value : "#ecf0f5") : null,
        };
        this.setState({ isUpdating: true });
        this.props.onLor.updateLor(this.props.lor.id, lor).then(() => {
            if (!this.props.isUpdating) {
                if (!this.props.error) {
                    this.props.generateAlert("success", "LoR updated.");
                    this.togleDialogModal();
                } else {
                    this.props.generateAlert("danger", this.props.error.errData.Message);
                }
            }
            this.setState({ isUpdating: this.props.isUpdating });
        });
    };

    handleChangeComplete = (name, color) => {
        const form = this.state.form;

        if (name == "categoryColor") {
            form.categoryColor.value = color.hex;
        } else if (name == "lorColor") {
            form.lorColor.value = color.hex;
        }

        this.setState({ form });
    };

    render() {
        const { form } = this.state;

        const popover = {
            position: "absolute",
            zIndex: "2",
        };
        const cover = {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
        };

        return (
            <React.Fragment>
                <Modal isOpen={this.state.showDialog} toggle={this.togleDialogModal} size="md">
                    <ModalHeader toggle={this.togleDialogModal}>Update LoR Category</ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col md={12} sm={12}>
                                    <FormGroup row>
                                        <Label sm={4}>Lor Name</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="name" id="name" value={form.name.value} invalid={form.name.touched && !form.name.isValidFormat} readOnly={true} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4}>Category</Label>
                                        <Col sm={8}>
                                            <Input type="text" name="category" id="category" value={form.category.value} onChange={this.handleChange} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.handlePerformSave();
                            }}
                            disabled={!this.state.isFormValid}
                        >
                            Save
                        </Button>
                        <Button color="secondary" onClick={this.togleDialogModal} disabled={this.state.isUpdating}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.lor.error,
        isUpdating: state.lor.isUpdating,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLor: bindActionCreators(lorActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLor);
