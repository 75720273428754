import { contentTranslator } from "../../utils/translatorUtils";
import { ContentWrapper } from "../common/ContentWrapper";
import ContentTranslator from "../translator/ContentTranslator";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { Button, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table } from "reactstrap";

class ListSurvey extends Component {
    static displayName = ListSurvey.name;

    constructor(props) {
        super(props);

        this.state = {
            showConfirmTutorial: false,
            selectedSurvey: null,
            surveyRating: 0,
            surveyComment: null,
        };
        this.togleConfirmTutorialModal = this.togleConfirmTutorialModal.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {}

    selectSurvey = (survey) => {
        const surveys = this.props.surveys;
        const started = surveys && surveys.some((it) => it.elements.some((it) => it.assessmentPersonResultId && (it.valueId || (it.values && it.values.length > 0))));

        let element = null;

        if (survey.elements.find((it) => it.lastOpened)) {
            element = survey.elements.sort((b, a) => new Date(a.lastOpened) - new Date(b.lastOpened)).filter((it) => it.assessmentPersonResultId && (it.valueId || (it.values && it.values.length > 0)) && it.lastOpened)[0];
        } else {
            element = survey.elements.sort((a, b) => a.displaySequence - b.displaySequence).filter((it) => !(it.assessmentPersonResultId && (it.valueId || (it.values && it.values.length > 0))))[0];
        }

        if (!started) {
            this.setState({ selectedSurvey: survey, showConfirmTutorial: true });
        } else {
            this.props.history.push(`/survey/question/${survey.componentGroupId}/${element.elementId}`);
        }
    };

    togleConfirmTutorialModal() {
        const showConfirmTutorial = this.state.showConfirmTutorial;
        this.setState({ showConfirmTutorial: !showConfirmTutorial });
    }

    showTutorial = () => {
        const selectedSurvey = this.state.selectedSurvey;

        if (selectedSurvey && selectedSurvey.assessmentTutorialUrl && selectedSurvey.assessmentTutorialUrl != "") {
            window.open(selectedSurvey.assessmentTutorialUrl);
        } else {
            window.open("https://skillstx.com/skillstx-personal-user-guide/");
        }
    };

    tutorialWatched = () => {
        const survey = this.state.selectedSurvey;
        const element = survey.elements.sort((a, b) => a.displaySequence - b.displaySequence).filter((it) => !(it.assessmentPersonResultId && (it.valueId || (it.values && it.values.length > 0))))[0];

        this.props.history.push(`/survey/question/${survey.componentGroupId}/${element.elementId}`);
    };

    modalConfirmTutorial() {
        return (
            <Modal isOpen={this.state.showConfirmTutorial} toggle={this.togleConfirmTutorialModal}>
                <ModalHeader toggle={this.togleConfirmTutorialModal}>
                    <ContentTranslator page="Assessment" name="AssessmentReadyConfirmationHeader" contentText="Are you ready?" />
                </ModalHeader>
                <ModalBody>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: this.contentTranslator(this.props.contentTranslations, "Assessment", "AssessmentReadyConfirmationMessage", "Great, you are about to start your skills inventory.<br />Have you attended an awareness session or watched the tutorial?"),
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={this.tutorialWatched}>
                        <ContentTranslator page="Assessment" name="AssessmentReadyConfirmationButtonYes" contentText="Yes" />
                    </Button>
                    <Button color="danger" onClick={this.showTutorial}>
                        <ContentTranslator page="Assessment" name="AssessmentReadyConfirmationButtonNo" contentText="No" />
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderTableBody(surveys) {
        const rows = surveys.map((row, index) => {
            const completed = row.elements && row.elements.length > 0 ? (row.elements.filter((it) => !it.assessmentPersonResultId || !(it.valueId || (it.values && it.values.length > 0))).length > 0 ? false : true) : true;

            const started = row.elements && row.elements.length > 0 ? (row.elements.filter((it) => it.assessmentPersonResultId && (it.valueId || (it.values && it.values.length > 0))).length > 0 ? true : false) : false;

            return (
                <tr key={index} className={completed ? "opacityElement" : ""}>
                    <td>{row.moduleName}</td>
                    <td>{row.description}</td>
                    <td className="text-center">{row.elements ? row.elements.length : 0}</td>
                    <td className="text-center">{row.timeEstimate}</td>
                    <td>
                        <Link to={"#"}>
                            <button style={{ width: "116px", minHeight: "33px", fontSize: 10, fontFamily: "IBMPlexSans-Bold" }} className={"btn " + (completed ? "btn-success " : started ? "btn-resume" : "btn-primary")} disabled={completed} onClick={() => this.selectSurvey(row)}>
                                {completed ? (
                                    <ContentTranslator page="Assessment" name="AssessmentButtonCompleted" contentText="Completed" />
                                ) : started ? (
                                    <ContentTranslator page="Assessment" name="AssessmentButtonResume" contentText="Resume Survey" />
                                ) : (
                                    <ContentTranslator page="Assessment" name="AssessmentButtonStart" contentText="Start Survey" />
                                )}
                            </button>
                        </Link>
                    </td>
                </tr>
            );
        });

        return <tbody style={{ fontSize: 11, fontFamily: "IBMPlexSans" }}>{rows}</tbody>;
    }

    changeRating = (newRating) => {
        this.setState({ surveyRating: newRating });
    };

    handleChangeComment = (event) => {
        const { value } = event.target;
        this.setState({ surveyComment: value });
    };

    renderRoleSelection() {
        const { surveyRoles } = this.props;

        const rows = surveyRoles.map((role, index) => {
            return (
                <div key={index}>
                    {index === 0 ? "" : <div className="line mt-2 border-secondary"></div>}
                    <Row className="mt-0 align-items-center" xs="1" sm="1" md="2">
                        <Col sx="12" md="9">
                            <p
                                className="color-dark mb-0"
                                style={{
                                    fontSize: 14,
                                    fontFamily: "IBMPlexSans",
                                }}
                            >
                                <b>
                                    <ContentTranslator page="Values" name={`${role.valueId} Name`} contentText={role.name} />
                                </b>
                                <br />
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: this.contentTranslator(this.props.contentTranslations, "Values", `${role.valueId} Label`, role.label),
                                    }}
                                />
                            </p>
                        </Col>
                        <Col sx="12" md="3">
                            <button
                                className="btn btn-block btn-primary"
                                style={{
                                    height: "55px",
                                    fontSize: 18,
                                    fontFamily: "IBMPlexSans-Bold",
                                    textTransform: "capitalize",
                                }}
                                disabled={this.props.inProgress}
                                onClick={() => this.props.selectRole(role.id)}
                            >
                                {this.props.inProgress && this.props.selectedRoleId && this.props.selectedRoleId == role.id ? (
                                    <Spinner type="grow" size="md" color="light" />
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: this.contentTranslator(this.props.contentTranslations, "Assessments", "SelectButton", "Select"),
                                        }}
                                    />
                                )}
                            </button>
                        </Col>
                    </Row>
                </div>
            );
        });

        return rows;
    }

    render() {
        const { surveys, surveyRoles } = this.props;

        const completionDateTarget = surveys && surveys.length > 0 && surveys[0].surveyCompletionTargetDate;
        const showSurveyRating = surveys && surveys.length > 0 && surveys[0].showSurveyRating;
        const assessmentName = surveys && surveys.length > 0 && surveys[0].assessmentName;

        let isComplete = false;

        if (surveys && surveys.length > 0) {
            const unAnswerred = surveys.filter((it) => it.elements.some((it) => !(it.assessmentPersonResultId && (it.valueId || (it.values && it.values.length > 0)))));
            if (unAnswerred.length == 0) {
                isComplete = true;
            }
        }

        return (
            <React.Fragment>
                {this.modalConfirmTutorial()}
                {isComplete && (
                    <ContentWrapper>
                        <Container>
                            <Row>
                                <Col className="p-0">
                                    {showSurveyRating && (
                                        <React.Fragment>
                                            <p>{!this.props.loginWithTeams ? <ContentTranslator page="Assessment" name="SurveyRateMessage" contentText="Please take a moment to rate your survey experience and create your account:" /> : "Please take a moment to rate your survey experience"}</p>
                                            <div>
                                                <StarRatings rating={this.state.surveyRating} starRatedColor="orange" changeRating={this.changeRating} numberOfStars={5} name="rating" starDimension="40px"></StarRatings>
                                            </div>
                                            <p>
                                                <ContentTranslator page="Assessment" name="SurveyRateReason" contentText="Reason for your rating (optional)" />
                                            </p>
                                            <p>
                                                <Input type="textarea" name="surveyComment" id={"surveyComment"} value={this.state.surveyComment ? this.state.surveyComment : ""} onChange={this.handleChangeComment} />
                                            </p>
                                        </React.Fragment>
                                    )}
                                    {!this.props.loginWithTeams ? (
                                        <React.Fragment>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: this.contentTranslator(this.props.contentTranslations, "Assessment", "CreateAccountMessage", "<p><strong>Create account</strong></p><p>Please create an account to enable access to view and maintain your Skills Profile</p>"),
                                                }}
                                            ></p>
                                            <div>
                                                <button
                                                    style={{ fontSize: 14, fontFamily: "IBMPlexSans-Bold" }}
                                                    className={"btn btn-success"}
                                                    onClick={() =>
                                                        this.props.createAccount(
                                                            showSurveyRating
                                                                ? {
                                                                      postRating: true,
                                                                      surveyRating: this.state.surveyRating >= 0 ? this.state.surveyRating : null,
                                                                      surveyComment: this.state.surveyComment,
                                                                  }
                                                                : {
                                                                      postRating: false,
                                                                  }
                                                        )
                                                    }
                                                >
                                                    <ContentTranslator page="Assessment" name="CreateAccountButton" contentText="Create Account" />
                                                </button>
                                                &nbsp;&nbsp;&nbsp;
                                                {this.props.samlEnabled && this.props.defaultCompany && this.props.samlEnabled == "TRUE" && (
                                                    <button
                                                        style={{ fontSize: 14, fontFamily: "IBMPlexSans-Bold" }}
                                                        className={"btn btn-success"}
                                                        onClick={() =>
                                                            this.props.submitRating(
                                                                showSurveyRating
                                                                    ? {
                                                                          postRating: true,
                                                                          surveyRating: this.state.surveyRating >= 0 ? this.state.surveyRating : null,
                                                                          surveyComment: this.state.surveyComment,
                                                                      }
                                                                    : {
                                                                          postRating: false,
                                                                      }
                                                            )
                                                        }
                                                    >
                                                        {`Proceed with your ${this.props.defaultCompany.name} account`}
                                                    </button>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <button
                                                style={{ fontSize: 14, fontFamily: "IBMPlexSans-Bold" }}
                                                className={"btn btn-success"}
                                                onClick={() =>
                                                    this.props.submitRatingTeams(
                                                        showSurveyRating
                                                            ? {
                                                                  postRating: true,
                                                                  surveyRating: this.state.surveyRating >= 0 ? this.state.surveyRating : null,
                                                                  surveyComment: this.state.surveyComment,
                                                              }
                                                            : {
                                                                  postRating: false,
                                                              }
                                                    )
                                                }
                                                disabled={this.props.loading}
                                            >
                                                {this.props.loading ? <Spinner type="grow" size="md" color="light" /> : "Submit"}
                                            </button>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </ContentWrapper>
                )}
                {surveys && surveys.length > 0 && (
                    <ContentWrapper>
                        <Container>
                            <Row>
                                <Col className="p-0">
                                    <p
                                        className="color-dark"
                                        style={{
                                            width: "383px",
                                            fontFamily: "IBMPlexSans-Bold",
                                            fontSize: 20,
                                        }}
                                    >
                                        {assessmentName}
                                    </p>
                                </Col>
                                {!isComplete && (
                                    <Col xs="auto" className="p-0">
                                        <button
                                            className="btn btn-block btn-danger"
                                            style={{
                                                height: "33px",
                                                fontSize: 10,
                                                fontFamily: "IBMPlexSans-Bold",
                                                textTransform: "capitalize",
                                            }}
                                            onClick={() => this.props.resetSurvey()}
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: this.contentTranslator(this.props.contentTranslations, "Assessments", "StartAgainButton", "Start again"),
                                                }}
                                            />
                                        </button>
                                    </Col>
                                )}
                                <Col xs="auto" className="p-0">
                                    <p style={{ width: "120px", fontFamily: "IBMPlexSans-Bold", fontSize: 11, textAlign: "right" }}>
                                        <ContentTranslator page="Assessment" name="TargetCompletion" contentText="Target date for completion:" />
                                        <br />
                                        <span className="color-primary">
                                            {completionDateTarget &&
                                                new Intl.DateTimeFormat("en-GB", {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric",
                                                }).format(new Date(completionDateTarget))}
                                        </span>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                        <Table>
                            <thead style={{ fontSize: 11, fontFamily: "IBMPlexSans-Bold", whiteSpace: "nowrap" }}>
                                <tr>
                                    <th>
                                        <ContentTranslator page="Assessment" name="TableHeaderSurveyFocus" contentText="Survey Focus" />
                                    </th>
                                    <th>
                                        <ContentTranslator page="Assessment" name="TableHeaderSurveyDescription" contentText="Description" />
                                    </th>
                                    <th>
                                        <ContentTranslator page="Assessment" name="TableHeaderSurveyNoOfQuestion" contentText="# Questions" />
                                    </th>
                                    <th>
                                        <ContentTranslator page="Assessment" name="TableHeaderSurveyEstDuration" contentText="Est. Duration" />
                                    </th>
                                    <th>
                                        <ContentTranslator page="Assessment" name="TableHeaderSurveyAction" contentText="Action" />
                                    </th>
                                </tr>
                            </thead>
                            {this.renderTableBody(surveys)}
                        </Table>
                    </ContentWrapper>
                )}
                {surveyRoles && surveyRoles.length > 0 && (
                    <ContentWrapper>
                        <Row>
                            <Col>
                                <p
                                    className="color-dark"
                                    style={{
                                        fontSize: 14,
                                        fontFamily: "IBMPlexSans-Light",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: this.contentTranslator(
                                            this.props.contentTranslations,
                                            "Assessment",
                                            "AssessmentRoleSelectionNote",
                                            `From the list below select the option that <b>best</b> matches your experience.<br />For extra guidance on the options below, or if you are unsure what we mean by 'digital career' please click here: <a href="https://skillstx.com/skillstx-personal-user-guide" target="_blank">Assessment Guidance</a><br />Your selection will filter your self-assessment to present surveys that are focused specifically on skills and skill levels which are most likely to be relevant to you. The more digital experience you have, the more skill questions you will be offered.<br />After you’ve completed the surveys, you will still have plenty of opportunity to further “refine” your skill profile, regardless of the option you choose below.<br /><b>NOTE:</b> On making your selection you will immediately be presented with your tailored self-assessment surveys.<br />`
                                        ),
                                    }}
                                ></p>
                            </Col>
                        </Row>
                        {this.renderRoleSelection()}
                    </ContentWrapper>
                )}
            </React.Fragment>
        );
    }
}

export default withRouter(ListSurvey);
