import { contentTranslator } from "../../../utils/translatorUtils";
import ContentTranslator from "../../translator/ContentTranslator";
import { faComment, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table, UncontrolledTooltip } from "reactstrap";

const tableBodyStyle = {
    fontSize: 11,
    fontFamily: "IBMPlexSans",
};

const tdEditStyle = {
    padding: "2px 10px",
};

const lorOrders = [
    { lor: "Autonomy", order: 1 },
    { lor: "Influence", order: 2 },
    { lor: "Complexity", order: 3 },
    { lor: "Business Skills", order: 4 },
    { lor: "Knowledge", order: 5 },
    { lor: "Responsibility and Autonomy", order: 1 },
    { lor: "Complexity and Scope", order: 2 },
    { lor: "Skills", order: 3 },
];

class ManageJobLorProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editLorProfile: false,
            editedLor: null,
            editedLorProfile: null,
            lorProfiles: this.props.lorProfiles,
        };
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lorProfiles !== this.props.lorProfiles) {
            this.setState({ lorProfiles: this.props.lorProfiles });
        }
    }

    renderLorProfileList = (categoryIndex, category, categoryColor, lorColor) => {
        const { containerConfig, lors, showHiddenSkills } = this.props;
        const { lorProfiles } = this.state;

        if (lors) {
            lors.forEach((lor) => {
                const lorOrder = lorOrders.find((it) => it.lor == lor.name);
                if (lorOrder) {
                    lor.order = lorOrder.order;
                } else {
                    lor.order = 999;
                }
            });
        }

        const rows = lors
            .filter((it) => (it.category ? it.category : "Generic Attributes") == (category ? category : "Generic Attributes"))
            //.sort((a, b) => (`${a.sequence}${a.order}${a.name}` > `${b.sequence}${b.order}${b.name}` ? 1 : -1))
            .sort((a, b) => {
                if (a.sequence > b.sequence) {
                    return 1;
                }
                if (a.sequence < b.sequence) {
                    return -1;
                }
                if (a.order > b.order) {
                    return 1;
                }
                if (a.order < b.order) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            })
            .map((lor, lorindex) => {
                const lorProfile = lorProfiles.find((x) => x.lor === lor.name);
                const lorHidden = lor.isHidden && !showHiddenSkills;
                const lorOptional = lor.optional;
                const levels = [1, 2, 3, 4, 5, 6, 7];

                if (lorProfile) {
                    const currLevel = lorProfile.edited ? (lorProfile.editedNumericVal ? lorProfile.editedNumericVal : 0) : lorProfile.numericVal;
                    return (
                        !lorHidden && (
                            <tr key={lorindex}>
                                <td id={"Item" + (lorProfile.id ? lorProfile.id : lorProfile.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, ""))} className={"pt-1 pb-1 td-item"} valign="middle" style={{ verticalAlign: "middle", width: "100%" }}>
                                    <ContentTranslator page="Lors" name={lorProfile.lor} contentText={lorProfile.lor} />
                                </td>
                                <UncontrolledTooltip target={"Item" + (lorProfile.id ? lorProfile.id : lorProfile.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, ""))} placement="bottom">
                                    {lor && <ContentTranslator page="Lors" name={`${lorProfile.lor} Description`} contentText={lor.description} />}
                                </UncontrolledTooltip>
                                <td className={"pt-1 pb-1 td-item"}></td>
                                {levels.map((level, levelindex) => {
                                    const lorLevel = lor.levels.find((x) => x.levelNumber == level);
                                    return (
                                        <React.Fragment key={levelindex}>
                                            <td
                                                id={`lv${categoryIndex}${level}${lorindex}${levelindex}`}
                                                className={"pt-1 pb-1 td-value td-fixwidth"}
                                                style={{
                                                    borderRight: "1px solid #FFFFFF",
                                                    backgroundColor: currLevel === level ? categoryColor : lorColor,
                                                    cursor: this.props.showEditSkill ? (containerConfig.enableClickToEditLoR ? (currLevel === level ? (lorOptional ? "pointer" : "default") : "default") : "default") : "default",
                                                }}
                                                onClick={() => this.props.showEditSkill && containerConfig.enableClickToEditLoR && ((currLevel === level && lorOptional) || currLevel != level) && this.handleSelectLorLevel(lor, level, lorProfile)}
                                            >
                                                {currLevel === level && lorProfile.comment && <FontAwesomeIcon icon={faComment} style={{ color: "#ffa500" }} />}
                                            </td>
                                            <UncontrolledTooltip target={`lv${categoryIndex}${level}${lorindex}${levelindex}`} placement="bottom">
                                                {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                            </UncontrolledTooltip>
                                        </React.Fragment>
                                    );
                                })}
                                {this.props.showEditSkill && (
                                    <td align="center" id={"lvEdit" + (lorProfile.id ? lorProfile.id : lorProfile.lor.replace(/ /g, "").replace(/,/g, "").replace(/&/g, ""))} style={tdEditStyle}>
                                        <button
                                            id={`btnEditLorCat${categoryIndex}Level${lorindex}`}
                                            className={`btn btn-light ${lorProfile.edited ? " btn-warning-edit" : " btnEdit-Style"}`}
                                            style={{ cursor: containerConfig.enableClickToEditLoR ? "default" : "pointer" }}
                                            onClick={() => !containerConfig.enableClickToEditLoR && this.openEditLorProfile(lor, { id: lorProfile.id, value: currLevel })}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        {containerConfig.enableClickToEditLoR && (
                                            <UncontrolledTooltip target={`btnEditLorCat${categoryIndex}Level${lorindex}`} placement="bottom">
                                                Click on the level cell to select LoR level
                                            </UncontrolledTooltip>
                                        )}
                                    </td>
                                )}
                            </tr>
                        )
                    );
                } else {
                    return (
                        !lorHidden && (
                            <tr key={lorindex}>
                                <td id={"Item" + lor.name.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "").replace(/\//g, "")} className={"pt-1 pb-1 td-item"} valign="middle" style={{ verticalAlign: "middle" }}>
                                    <ContentTranslator page="Lors" name={lor.name} contentText={lor.name} />
                                </td>
                                <UncontrolledTooltip target={"Item" + lor.name.replace(/ /g, "").replace(/,/g, "").replace(/&/g, "").replace(/\//g, "")} placement="bottom">
                                    {lor && <ContentTranslator page="Lors" name={`${lor.name} Description`} contentText={lor.description} />}
                                </UncontrolledTooltip>
                                <td className={"pt-1 pb-1 td-item"}></td>
                                {levels.map((level, levelindex) => {
                                    const lorLevel = lor.levels.find((x) => x.levelNumber == level);
                                    return (
                                        <React.Fragment key={levelindex}>
                                            <td
                                                id={`lv${categoryIndex}${level}${lorindex}${levelindex}`}
                                                className={"pt-1 pb-1 td-value td-fixwidth"}
                                                style={{ backgroundColor: lorColor, cursor: this.props.showEditSkill && containerConfig.enableClickToEditLoR ? "pointer" : "default" }}
                                                onClick={() => this.props.showEditSkill && containerConfig.enableClickToEditLoR && this.handleSelectLorLevel(lor, level, null)}
                                            ></td>
                                            <UncontrolledTooltip target={`lv${categoryIndex}${level}${lorindex}${levelindex}`} placement="bottom">
                                                {lorLevel && <ContentTranslator page="LorLevels" name={`${lor.name} ${lorLevel.levelName} Description`} contentText={lorLevel.description} />}
                                            </UncontrolledTooltip>
                                        </React.Fragment>
                                    );
                                })}
                                {this.props.showEditSkill && (
                                    <td align="center" id={"lvEdit" + lor.name} style={tdEditStyle}>
                                        <button
                                            id={`btnEditLorCat${categoryIndex}Level${lorindex}`}
                                            className="btn btn-light btnEdit-Style"
                                            style={{ cursor: containerConfig.enableClickToEditLoR ? "default" : "pointer" }}
                                            onClick={() => !containerConfig.enableClickToEditLoR && this.openEditLorProfile(lor, null)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        {containerConfig.enableClickToEditLoR && (
                                            <UncontrolledTooltip target={`btnEditLorCat${categoryIndex}Level${lorindex}`} placement="bottom">
                                                Click on the level cell to select LoR level
                                            </UncontrolledTooltip>
                                        )}
                                    </td>
                                )}
                            </tr>
                        )
                    );
                }
            });

        return <tbody style={tableBodyStyle}>{rows}</tbody>;
    };

    openEditLorProfile = (editedLor, editedLorProfile) => {
        this.setState({ editLorProfile: true, editedLor, editedLorProfile });
    };

    togleEditLorProfile = () => {
        const { editLorProfile } = this.state;
        this.setState({ editLorProfile: !editLorProfile });
    };

    renderModalEditProfile() {
        const { editedLor, editedLorProfile } = this.state;

        let lorProfileElement = null;

        if (editedLorProfile) {
            lorProfileElement = {
                id: editedLorProfile.id,
                value: editedLorProfile.value,
            };
        }
        return (
            <Modal isOpen={this.state.editLorProfile} toggle={this.togleEditLorProfile}>
                <ModalHeader toggle={this.togleEditLorProfile}>Edit Responsibility</ModalHeader>
                <ModalBody>
                    <Form>
                        <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "500px" }}>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr valign="top">
                                        <td colSpan="3" style={{ width: "100%", backgroundColor: editedLor && editedLor.categoryColor ? editedLor.categoryColor : "#69849c" }} className="td-edit-skill-header">
                                            {editedLor && <ContentTranslator page="Lors" name={editedLor.name} contentText={editedLor.name} />}
                                        </td>
                                    </tr>
                                    <tr valign="top">
                                        <td colSpan="3" style={{ width: "100%", backgroundColor: editedLor && editedLor.categoryColor ? editedLor.categoryColor : "#69849c" }} className="td-edit-skill-header">
                                            {editedLor && <ContentTranslator page="Lors" name={`${editedLor.name} Description`} contentText={editedLor.description} />}
                                        </td>
                                    </tr>
                                    {editedLor &&
                                        editedLor.levels &&
                                        editedLor.levels.map((level, index) => {
                                            return (
                                                <tr key={index} valign="top">
                                                    <td style={{ width: "5%" }} className="td-edit-profile-skill">
                                                        {level.levelNumber}
                                                    </td>
                                                    <td style={{ width: "87%" }} className="td-edit-profile-skill">
                                                        <ContentTranslator page="LorLevels" name={`${editedLor.name} ${level.levelName} Description`} contentText={level.description} />
                                                    </td>
                                                    <td style={{ width: "8%" }} className="td-edit-profile-skill">
                                                        <FormGroup>
                                                            <Input
                                                                type="radio"
                                                                value={level.levelNumber}
                                                                name={`rad${editedLor.id}`}
                                                                checked={lorProfileElement ? (lorProfileElement.value === level.levelNumber ? true : false) : false}
                                                                onChange={() => null}
                                                                onClick={() => this.handleRadioClick(level.levelNumber)}
                                                            />
                                                        </FormGroup>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.togleEditLorProfile}>
                        Close
                    </Button>{" "}
                    {editedLor && editedLor.optional && editedLorProfile && editedLorProfile.value > 0 && (
                        <React.Fragment>
                            {" "}
                            <Button color="primary" onClick={this.handleClearProfileClick}>
                                Clear Selection
                            </Button>
                        </React.Fragment>
                    )}
                    <Button color="primary" onClick={this.handleSaveEditProfileClick}>
                        Save changes
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    handleRadioClick = (value) => {
        let editedLorProfile = this.state.editedLorProfile;

        if (editedLorProfile) {
            editedLorProfile.value = value;
        } else {
            editedLorProfile = {
                value,
            };
        }

        this.setState({ editedLorProfile });
    };

    handleClearProfileClick = () => {
        const { lorProfiles, editedLor, editedLorProfile } = this.state;

        let lorProfile = null;
        if (lorProfiles && editedLor.optional && editedLorProfile) {
            lorProfile = lorProfiles.find((it) => (editedLorProfile.id && it.id == editedLorProfile.id) || (!it.id && it.lor === editedLor.name));

            console.log("lorProfile", lorProfile);

            if (lorProfile) {
                if (lorProfile.id) {
                    lorProfile.editedLevel = null;
                    lorProfile.editedNumericVal = 0;
                    lorProfile.editedStringVal = null;
                    lorProfile.editedLabelVal = null;

                    lorProfile.edited = true;

                    lorProfile.source = "lorProfiles";
                } else {
                    lorProfile.level = null;
                    lorProfile.numericVal = null;
                    lorProfile.stringVal = null;
                    lorProfile.labelVal = null;

                    lorProfile.editedLevel = null;
                    lorProfile.editedNumericVal = 0;
                    lorProfile.editedStringVal = null;
                    lorProfile.editedLabelVal = null;
                    lorProfile.edited = false;

                    lorProfile.source = "lorProfiles";
                }

                this.props.handleEditProfileSkill(lorProfile);
                this.setState({ lorProfiles, editLorProfile: false });
            }
        }

        if (!lorProfile) {
            this.setState({ editLorProfile: false });
        }
    };

    handleSaveEditProfileClick = () => {
        const { lors } = this.props;
        const { lorProfiles, editedLor, editedLorProfile } = this.state;

        const level = lors.find((it) => it.name == editedLor.name).levels.find((level) => level.levelNumber == editedLorProfile.value);

        let lorProfile = null;
        if (lorProfiles && editedLorProfile) {
            lorProfile = lorProfiles.find((it) => (editedLorProfile.id && it.id == editedLorProfile.id) || (!it.id && it.lor === editedLor.name));

            if (lorProfile) {
                if (((lorProfile.editedNumericVal || lorProfile.editedNumericVal == 0) && lorProfile.editedNumericVal != editedLorProfile.value) || (!lorProfile.editedNumericVal && lorProfile.numericVal != editedLorProfile.value)) {
                    if (lorProfile.id) {
                        if (lorProfile.level == level.levelNumber) {
                            // Updated back to original level
                            lorProfile.editedLevel = null;
                            lorProfile.editedNumericVal = null;
                            lorProfile.editedStringVal = null;
                            lorProfile.editedLabelVal = null;
                            lorProfile.edited = false;
                        } else {
                            lorProfile.editedLevel = level.levelNumber;
                            lorProfile.editedNumericVal = editedLorProfile.value;
                            lorProfile.editedStringVal = level && `${level.levelNumber}- ${level.levelName}`;
                            lorProfile.editedLabelVal = level && level.description;
                            lorProfile.edited = true;
                        }
                    } else {
                        lorProfile.editedLevel = level.levelNumber;
                        lorProfile.editedNumericVal = editedLorProfile.value;
                        lorProfile.editedStringVal = level && `${level.levelNumber}- ${level.levelName}`;
                        lorProfile.editedLabelVal = level && level.description;
                        lorProfile.edited = true;
                    }

                    lorProfile.source = "lorProfiles";
                    this.props.handleEditProfileSkill(lorProfile);
                    this.setState({ lorProfiles, editLorProfile: false });
                } else {
                    this.setState({ editLorProfile: false });
                }
            }
        }

        if (!lorProfile && level) {
            lorProfile = {
                lor: editedLor.name,
                lorCode: editedLor.lorCode,
                level: level.levelNumber,
                numericVal: editedLorProfile.value,
                stringVal: level && `${level.levelNumber}- ${level.levelName}`,
                labelVal: level && level.description,
                editedLevel: level.levelNumber,
                editedNumericVal: editedLorProfile.value,
                editedStringVal: level && `${level.levelNumber}- ${level.levelName}`,
                editedLabelVal: level && level.description,
                edited: true,
                source: "lorProfiles",
            };

            this.props.handleEditProfileSkill(lorProfile);
            this.setState({ lorProfiles: [...lorProfiles, lorProfile], editLorProfile: false });
        }
    };

    handleSelectLorLevel = (lor, level, editedLorProfile) => {
        const { lors } = this.props;
        const { lorProfiles } = this.state;
        const lorLevel = lors.find((it) => it.name == lor.name).levels.find((it) => it.levelNumber == level);

        let lorProfile = null;
        if (lorProfiles && editedLorProfile) {
            lorProfile = lorProfiles.find((it) => (editedLorProfile.id && it.id == editedLorProfile.id) || (!it.id && it.lor === lor.name));

            if (lorProfile) {
                if (lorProfile.id) {
                    // Update existing lor profile
                    if (
                        // First update
                        (!lorProfile.editedNumericVal && lorProfile.numericVal != level) ||
                        // Update after first update
                        (lorProfile.editedNumericVal && lorProfile.editedNumericVal != level)
                    ) {
                        // Click on different level cell
                        if (lorProfile.level == level) {
                            // Updated back to original level
                            lorProfile.editedLevel = null;
                            lorProfile.editedNumericVal = null;
                            lorProfile.editedStringVal = null;
                            lorProfile.editedLabelVal = null;
                            lorProfile.edited = false;
                        } else {
                            lorProfile.editedLevel = lorLevel.levelNumber;
                            lorProfile.editedNumericVal = level;
                            lorProfile.editedStringVal = lorLevel && `${lorLevel.levelNumber}- ${lorLevel.levelName}`;
                            lorProfile.editedLabelVal = lorLevel && lorLevel.description;
                            lorProfile.edited = true;
                        }

                        lorProfile.source = "lorProfiles";

                        this.props.handleEditProfileSkill(lorProfile);
                        this.setState({ lorProfiles });
                    } else if (
                        lor.optional &&
                        // First update
                        ((!lorProfile.editedNumericVal && lorProfile.numericVal == level) ||
                            // Update after first update
                            (lorProfile.editedNumericVal && lorProfile.editedNumericVal == level))
                    ) {
                        // Click on same level cell, must be Optional Lor
                        if (lorProfile.editedNumericVal == 0) {
                            lorProfile.editedLevel = lorLevel.levelNumber;
                            lorProfile.editedNumericVal = level;
                            lorProfile.editedStringVal = lorLevel && `${lorLevel.levelNumber}- ${lorLevel.levelName}`;
                            lorProfile.editedLabelVal = lorLevel && lorLevel.description;
                        } else {
                            lorProfile.editedLevel = null;
                            lorProfile.editedNumericVal = 0;
                            lorProfile.editedStringVal = null;
                            lorProfile.editedLabelVal = null;
                        }

                        lorProfile.source = "lorProfiles";

                        if (lorProfile.numericVal != lorProfile.editedNumericVal) {
                            lorProfile.edited = true;
                        } else {
                            lorProfile.edited = false;
                        }

                        this.props.handleEditProfileSkill(lorProfile);
                        this.setState({ lorProfiles });
                    }
                } else {
                    // Update new selected lor profile
                    if (!lorProfile.editedNumericVal || lorProfile.editedNumericVal == 0 || (lorProfile.editedNumericVal && lorProfile.editedNumericVal != level)) {
                        lorProfile.level = lorLevel.levelNumber;
                        lorProfile.numericVal = level;
                        lorProfile.stringVal = lorLevel && `${lorLevel.levelNumber}- ${lorLevel.levelName}`;
                        lorProfile.labelVal = lorLevel && lorLevel.description;

                        lorProfile.editedLevel = lorLevel.levelNumber;
                        lorProfile.editedNumericVal = level;
                        lorProfile.editedStringVal = lorLevel && `${lorLevel.levelNumber}- ${lorLevel.levelName}`;
                        lorProfile.editedLabelVal = lorLevel && lorLevel.description;
                        lorProfile.edited = true;

                        lorProfile.source = "lorProfiles";

                        this.props.handleEditProfileSkill(lorProfile);
                        this.setState({ lorProfiles });
                    } else if (lorProfile.editedNumericVal && lorProfile.editedNumericVal == level) {
                        lorProfile.level = null;
                        lorProfile.numericVal = null;
                        lorProfile.stringVal = null;
                        lorProfile.labelVal = null;

                        lorProfile.editedLevel = null;
                        lorProfile.editedNumericVal = 0;
                        lorProfile.editedStringVal = null;
                        lorProfile.editedLabelVal = null;
                        lorProfile.edited = false;

                        lorProfile.source = "lorProfiles";

                        this.props.handleEditProfileSkill(lorProfile);
                        this.setState({ lorProfiles });
                    }
                }
            }
        }

        if (!lorProfile && lorLevel) {
            lorProfile = {
                lor: lor.name,
                lorCode: lor.lorCode,
                level: lorLevel.levelNumber,
                numericVal: level,
                stringVal: lorLevel && `${lorLevel.levelNumber}- ${lorLevel.levelName}`,
                labelVal: lorLevel && lorLevel.description,
                editedLevel: lorLevel.levelNumber,
                editedNumericVal: level,
                editedStringVal: lorLevel && `${lorLevel.levelNumber}- ${lorLevel.levelName}`,
                editedLabelVal: lorLevel && lorLevel.description,
                edited: true,
                source: "lorProfiles",
            };

            this.props.handleEditProfileSkill(lorProfile);
            this.setState({ lorProfiles: [...lorProfiles, lorProfile] });
        }
    };

    render() {
        const props = this.props;
        const { lors, showHiddenSkills } = this.props;

        const lorCategories = [];

        if (lors && lors.find((it) => !it.category)) {
            lorCategories.push({
                category: "Generic Attributes",
                categoryColor: "#69849c",
                lorColor: "#ecf0f5",
                lors: lors.find((it) => !it.category) ? [...lors.filter((it) => !it.category)] : [],
            });
        }

        if (lors) {
            lors.filter((it) => it.category).forEach((lor) => {
                if (!lorCategories.find((it) => it.category == lor.category)) {
                    lorCategories.push({
                        category: lor.category,
                        categoryColor: lor.categoryColor,
                        lorColor: lor.lorColor,
                        lors: lors.find((it) => it.category == lor.category) ? [...lors.filter((it) => it.category == lor.category)] : [],
                    });
                }
            });
        }

        return (
            <React.Fragment>
                {this.renderModalEditProfile()}
                {lorCategories.map((lorCategory, index) => {
                    const categoryHidden = !lorCategory.lors.some((lor) => !lor.isHidden) && !showHiddenSkills;

                    return (
                        <React.Fragment key={index}>
                            {!categoryHidden && (
                                <Table>
                                    <thead className="tableHeader">
                                        <tr>
                                            <th className={"th-start"} style={{ backgroundColor: lorCategory.categoryColor }}>
                                                {lorCategory.category == "Generic Attributes" ? <ContentTranslator page="SfiaProfile" name="LorProfileTitle" contentText="Generic Attributes" /> : lorCategory.category}
                                            </th>
                                            <th style={{ backgroundColor: lorCategory.categoryColor }}></th>
                                            <th className={"th-fixwidth"} style={{ backgroundColor: lorCategory.categoryColor, textAlign: "center" }}>
                                                1
                                            </th>
                                            <th className={"th-fixwidth"} style={{ backgroundColor: lorCategory.categoryColor, textAlign: "center" }}>
                                                2
                                            </th>
                                            <th className={"th-fixwidth"} style={{ backgroundColor: lorCategory.categoryColor, textAlign: "center" }}>
                                                3
                                            </th>
                                            <th className={"th-fixwidth"} style={{ backgroundColor: lorCategory.categoryColor, textAlign: "center" }}>
                                                4
                                            </th>
                                            <th className={"th-fixwidth"} style={{ backgroundColor: lorCategory.categoryColor, textAlign: "center" }}>
                                                5
                                            </th>
                                            <th className={"th-fixwidth"} style={{ backgroundColor: lorCategory.categoryColor, textAlign: "center" }}>
                                                6
                                            </th>
                                            <th className={"th-fixwidth" + (props.showEdit ? "" : " th-end")} style={{ backgroundColor: lorCategory.categoryColor, textAlign: "center" }}>
                                                7
                                            </th>
                                            {props.showEdit && <th className={"th-fixwidth th-end"} style={{ backgroundColor: lorCategory.categoryColor }}></th>}
                                        </tr>
                                    </thead>
                                    {this.renderLorProfileList(index, lorCategory.category, lorCategory.categoryColor, lorCategory.lorColor)}
                                </Table>
                            )}
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contentTranslations: state.content.contentTranslations,
        containerConfig: state.config.containerConfig,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageJobLorProfile);
